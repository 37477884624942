
/** =============================
 * 
 * To show the rate of each card as (Incrementer or Decrementer in Percentage)
 * I have created Increaser and Decreaser in utills. 
 * 
 * 
 ============================= */


import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { useNavigate } from 'react-router-dom';
import TotalTestDone from 'src/components/dashboard/TotalTest';
import PeopleAtModerateRisk from 'src/components/dashboard/PeopleMR';
import PeopleAtHighRisk from 'src/components/dashboard/PeopleHR';
import PeopleAtLowRisk from 'src/components/dashboard/PeopleLR';

// Stylesheet
import classes from 'src/styles/dashboard/dashboard.module.scss';

export default function AllStatsView({allStats}) {

  const navigate = useNavigate();

  const redirect = (slug) => {
    navigate(`/app/employee/${slug}`)
  }


  return (
    <>
      <Grid item lg={3} sm={6} xl={3} xs={12}>
        {
          allStats.all_people_test_count && allStats.total_test_done &&
          <TotalTestDone testCount={allStats.total_test_done.count} empCount={allStats.all_people_test_count.count} />
        }
      </Grid>
      <Grid item lg={3} sm={6} xl={3} xs={12}>
        <Box
          className={classes.stat__box}
          onClick={() => redirect('people-on-alert')}
        >
          {
            allStats.high_risk_people_count && 
            <PeopleAtHighRisk count={allStats.high_risk_people_count.count} />
          }
        </Box>
      </Grid>
      <Grid item lg={3} sm={6} xl={3} xs={12}>
        <Box 
          className={classes.stat__box}
          onClick={() => redirect('people-on-moderate')}
        >
          {
            allStats.moderate_risk_people_count && 
            <PeopleAtModerateRisk count={allStats.moderate_risk_people_count.count} />
          }
        </Box>
      </Grid>
      <Grid item lg={3} sm={6} xl={3} xs={12}>
        <Box 
          className={classes.stat__box}
          onClick={() => redirect('people-on-low')}
        >
          {
            allStats.low_risk_people_count && 
            <PeopleAtLowRisk count={allStats.low_risk_people_count.count} />
          }
        </Box>
      </Grid>
    </>
  );
}
