import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer
} from 'recharts';

export default function AreaChartView({ graphData }) {
  return (
    <ResponsiveContainer className="responsive__chart" >
      <AreaChart
        data={graphData}
        margin={{
          top: 10,
          right: 30,
          left: 0,
          bottom: 0
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Area
          type="monotone"
          dataKey="reading1"
          stroke="#0D809F"
          fill="#0D809F"
        />
      </AreaChart>
    </ResponsiveContainer>
  );
}
