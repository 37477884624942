
import {
  Grid,
  Box,
  Typography,
  Hidden,
  Divider,
  Button
} from "@material-ui/core";
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import { useNavigate, createSearchParams } from "react-router-dom";

import EmployeeRecentTest from "./EmployeeRecentTest";

// Stylesheet
import classes from "src/styles/employee/employeeInfo.module.scss";


const EmployeeVitalsReportView = ({ lastRecentTests }) => {

  const navigate = useNavigate();

  const handleRedirect = () => {
    let pathName = window.location.pathname;
    navigate({
      pathname: pathName,
      search: `?${createSearchParams({
          tab: "2"
      })}`
    })

    console.log(window.location.pathname)
  }

  return(
    <Grid container spacing={3}>

    {/* Maybe in future we will swap you again :) */}

      <Grid item xs={12} sm={12} md={6}>
        
        <Box className={classes.right__container}>
          <Typography className={classes.right__container__title} gutterBottom>Last Test</Typography>
          <EmployeeRecentTest lastRecentTests={lastRecentTests && lastRecentTests.vitals} />
          <Divider sx={{marginTop: 2}} />
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              p: 0.6
            }}
          >
            <Button
              color="primary"
              endIcon={<ArrowRightIcon />}
              size="small"
              variant="text"
              onClick={handleRedirect}
            >
              View All Vital Logs
            </Button>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12} sm={12} md={6} className={classes.left__container}>
          <Hidden smDown>
            <img src="/static/images/vitals.png" className={classes.left__container__img} />
          </Hidden>
      </Grid>
    </Grid>
  )
}

export default EmployeeVitalsReportView;