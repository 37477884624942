import { useState } from 'react';
import { Box, Typography, Chip, Button } from '@material-ui/core';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';


/* ======= Views ======= */
import ConnectDialog from './connectDialog';

/* ======= Stylesheet ======= */
import classes from 'src/styles/ourDoctor/ourDoctor.module.scss';


/**
 * 
 * Doctor Profile Screen for other doctors 
 * who are not associated with organization
 * 
 */


export default function OtherDoctorRightView({profile}) {

  const [open, setOpen] = useState(false);


  return (
    <Box p={2} className={classes.od_prof_right__root}>
      <Box className={classes.inner__box}>
        <Typography className={classes.title}>About Doctor</Typography>
        <Typography className={classes.description}>
          Lorem Ipsum is simply dummy text of the printing and typesetting
          industry. Lorem Ipsum has been the industry's standard dummy text ever
          since the 1500s, when an unknown printer took a galley of type and
          scrambled it to make a type specimen book. It has survived not only
          five centuries, but also the leap into electronic typesetting,
          remaining essentially unchanged. It was popularised in the 1960s with
          the release of Letraset sheets containing Lorem Ipsum passages, and
          more recently with desktop publishing software like Aldus PageMaker
          including versions of Lorem Ipsum.
        </Typography>
        <Box mt={3}>
          <Typography className={classes.title}>Speciality</Typography>
          <Box mt={2}>
            {
              profile.qualification.length !== 0 
              ?
              <>
                {
                  profile.speciality.map((item, index) => (
                    <Chip key={index} label={item.name} className={classes.chip} />
                  ))
                }
              </>
              :
              <>
                <Typography>Doctor has no speciality.</Typography>
              </>
            }
          </Box>
        </Box>
        <Box mt={3}>
          <Typography className={classes.title}>Qualification</Typography>
          <Box mt={2}>
            {
              profile.qualification.length !== 0 
              ?
              <>
                {
                  profile.qualification.map((item, index) => (
                    <Chip key={index} label={item.degree} className={classes.chip} />
                  ))
                }
              </>
              :
              <>
                <Typography>Doctor has no qualification.</Typography>
              </>
            }
          </Box>
        </Box>
      </Box>
      <Box className={classes.footer__box} mt={3}>
        <Button 
          variant="contained" 
          endIcon={<ArrowRightAltIcon />}
          onClick={() => {
            setOpen(true)
          }}
        >
          Connect with Doctor
        </Button>
      </Box>
      {
        open && 
        <ConnectDialog 
          open
          setOpen={setOpen}
          profile={profile}
          outer={false}
        />
      }
    </Box>
  );
}
