import {
  Grid,
  Box,
} from "@material-ui/core";

/* ======== Tools ======== */
import { useParams } from "react-router-dom";
import getParameterByName from "src/utils/getSearchParams";

/* ======== Views ========= */
import LeftView from "./left";
import OtherDoctorRightView from "./otherDctorRight";
import OrgDoctorRightView from "./orgDoctorRight";
import PendingDoctorRightView from "./pendingDoctor";

const Doctor = ({profile, assignedEmp}) => {

  const { org } = useParams();
  const connect = getParameterByName("connect");

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={12} md={4} lg={3}>
        <LeftView profile={profile} />
      </Grid>
      <Grid item xs={12} sm={12} md={8} lg={9}>
        {
          org !== "has" && connect === 'true'
          ?
          <OtherDoctorRightView profile={profile} />
          :
          org === "pending" && connect === 'false'
          ?
          <PendingDoctorRightView profile={profile} />
          :
          <OrgDoctorRightView profile={profile} assignedEmp={assignedEmp} />
        }
      </Grid>
    </Grid>
  )
}

export default Doctor;