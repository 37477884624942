import moment from 'moment';
import {
  Box,
  Card,
  CardHeader,
  Chip,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from '@material-ui/core';
import { useNavigate } from "react-router-dom";


const Last10Test = ({ employee, ...props }) => {
  console.log("employee", employee)

  const navigate = useNavigate();

  return (
    <Card
      sx={{
        boxShadow: '0px 2px 12px rgb(21 21 22 / 4%) !important',
        border: '1px solid #E6EAEE',
        borderRadius: "13px",
        height: "100%"
      }}
      {...props}
    >
      <CardHeader title="Last Test" />
      <Box  >
        <Table className="table__container">
          <TableHead sx={{ background: "#0D809F" }}>
            <TableRow>
              <TableCell sx={{ color: "#fff" }}>Employee ID</TableCell>
              <TableCell sx={{ color: "#fff" }}>Employee Name</TableCell>
              <TableCell sx={{ color: "#fff" }}>Company Name</TableCell>
              <TableCell sx={{ color: "#fff" }}>Location</TableCell>
              <TableCell sx={{ color: "#fff" }} sortDirection="desc">
                Date {"&"} Time
              </TableCell>
              <TableCell sx={{ color: "#fff" }}>Status</TableCell>
            </TableRow>
          </TableHead>
          {
            employee.length > 0
              ?
              <TableBody>
                {employee.map((emp) => (
                  <TableRow key={emp.id}>

                    <TableCell sx={{ textTransform: "uppercase" }}>{emp.profile ? emp.profile.employee_code : "emp code"}</TableCell>
                    <TableCell
                      sx={{
                        textTransform: "capitalize",
                        "&:hover": {
                          textDecoration: "underline",
                          cursor: "pointer"
                        }
                      }}

                    >
                      {emp.profile ? emp.profile.name : "emp name"}
                    </TableCell>
                    <TableCell sx={{ textTransform: "capitalize" }}>{emp.profile ? emp.profile.company : "null"}</TableCell>
                    <TableCell sx={{ textTransform: "capitalize" }}>{emp.profile ? emp.profile.location : "null"}</TableCell>
                    <TableCell>
                      {moment(emp.created_on).format('DD/MM/YYYY')} {"- "}
                      {moment(emp.created_on).format('LT')}
                    </TableCell>
                    <TableCell>
                      <Chip
                        label={Boolean(emp.status) ? emp.status : "Unknown"}
                        size="small"
                        sx={{
                          background: emp.status === "alert"
                            ? "red"
                            : emp.status === "moderate"
                              ? "#F47920"
                              : emp.status === "good"
                                ? "limegreen"
                                : "gray",

                          color: "#fff",
                          textTransform: "capitalize"
                        }}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
              :
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  maxHeight: "390px",
                  minHeight: "390px"
                }}
              >
                <img src="/static/images/no_data.svg" alt="no_data_available" width="100px" />
                <Typography
                  sx={{
                    fontSize: 16,
                    paddingTop: 3,
                    color: "gray",
                    fontWeight: 500,
                    letterSpacing: "1.5px"
                  }}
                >
                  No recent tests yet.
                </Typography>
              </Box>
          }
        </Table>
      </Box>
    </Card >
  );
}

export default Last10Test;
