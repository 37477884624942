import { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Box, Container, Typography, Button, TextField, InputAdornment, CircularProgress } from '@material-ui/core';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import SearchIcon from '@material-ui/icons/Search';
import { useParams } from 'react-router-dom';

// @react-redux
import { connect } from "react-redux";
import { employee } from "src/redux/actions";

import EmployeeListResults from 'src/components/employee/EmployeeListResults';
import AddNewEmployee from 'src/components/employee/addNewEmployee';
import useDebounce from 'src/hooks/useDebounce';

// Stylesheet
import classes from 'src/styles/employee/employee.module.scss';

const EmployeeList = ({
  getEmployee,
  totalEmployee,
  addNewEmployee
}) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading ] = useState(false);
  const [searchValue, setSearchValue] = useState(null);
  const debounceSearchTerm = useDebounce(searchValue, 1000);
  const { slug } = useParams();


  useEffect(() => {
    if(debounceSearchTerm){
      setLoading(true)
      if(slug === 'people-on-alert'){
        getEmployee(`search=${debounceSearchTerm !== "-1" ? debounceSearchTerm : ''}&status=alert`)
          .then(res => setLoading(false))
          .catch(err => setLoading(false))
      } else if(slug === 'people-on-moderate')  {
        getEmployee(`search=${debounceSearchTerm !== "-1" ? debounceSearchTerm : ''}&status=moderate`)
          .then(res => setLoading(false))
          .catch(err => setLoading(false))
      } else if(slug === 'people-on-low') {
        getEmployee(`search=${debounceSearchTerm !== "-1" ? debounceSearchTerm : ''}&status=good`)
          .then(res => setLoading(false))
          .catch(err => setLoading(false))
      } else {
        getEmployee(`search=${debounceSearchTerm !== "-1" ? debounceSearchTerm : ''}`)
          .then(res => setLoading(false))
          .catch(err => setLoading(false))
      } 
    }
  },[debounceSearchTerm])

  useEffect(() => {
    setLoading(true)
    if(slug === 'people-on-alert'){
      getEmployee(`status=alert`)
        .then(res => setLoading(false))
        .catch(err => setLoading(false))
    } else if(slug === 'people-on-moderate') {
      getEmployee(`status=moderate`)
        .then(res => setLoading(false))
        .catch(err => setLoading(false))
    } else if(slug === 'people-on-low') {
      getEmployee(`status=good`)
        .then(res => setLoading(false))
        .catch(err => setLoading(false))
    } else {
      getEmployee(`page=1`)
        .then(res => setLoading(false))
        .catch(err => setLoading(false))
    }
  },[slug])

  return (
    <>
      <Helmet>
        <title>Employee | TraceLyfe</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.secondary',
          minHeight: '100%',
          py: 3
        }}
      >
        <Container maxWidth={false} className={classes.pt__root}>
          <Box className={classes.pt__header}>
            <Typography className={classes.pt__title}>
              {
                slug === 'people-on-alert'
                ?
                <>Employee on High Risk</>
                :
                slug === 'people-on-moderate'
                ?
                <>Employee on Moderate Risk</>
                :
                slug === 'people-on-low'
                ?
                <>Employee on Low Risk</>
                :
                <>Employee</>
              }
              
            </Typography>
            <Box className={classes.pt__header__inner}>
              <TextField 
                variant="standard"
                placeholder="employee id, name, email"
                // label="Search Employee"
                onChange={(e) => setSearchValue(e.target.value)}
                // sx={{
                //   marginRight: 3
                // }}
                className={classes.pt__header__inner__searchInput}
                InputProps={{
                  startAdornment: <InputAdornment sx={{paddingRight: 1}}>
                    <SearchIcon />
                  </InputAdornment>
                }}
              />
              <Button
                variant="contained"
                sx={{
                  textTransform: 'capitalize'
                }}
                startIcon={<PersonAddIcon />}
                onClick={() => setOpen(true)}
              >
                Add New Employee
              </Button>
            </Box>
          </Box>
          <Box sx={{ pt: 3 }}>
           {
             loading
             ?
             <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "70vh"
              }}
             >
              <CircularProgress />
             </Box>
             :
             <>
              {
                totalEmployee && totalEmployee.results.length !== 0 ?
                <EmployeeListResults 
                  employee={totalEmployee}  
                  fetchEmployee={getEmployee}
                  loading={loading}
                  setLoading={setLoading}
                />
                :
                <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" minHeight="70vh">
                  <PeopleAltIcon sx={{fontSize: 60, color: "darkslategray"}} />
                  <Typography 
                    sx={{
                      fontSize: 20, 
                      lineHeight: "3rem",
                      letterSpacing: "0.05em",
                      fontWeight: 500,
                      color: "darkslategray"
                    }}
                  >
                    Please add a user.
                  </Typography>
                </Box>
              }
             </>
           }
          </Box>
        </Container>
      </Box>
      {
        open &&
        <AddNewEmployee 
          open={open}
          setOpen={setOpen}
          addNewEmployee={addNewEmployee}
        />
      }
    </>
  );
};

const mapStateToProps = state => ({
  totalEmployee: state.employee.totalEmployee,
})

const mapDispatchToProps = dispatch => ({
  getEmployee: (data) => dispatch(employee.getEmployee(data)),
  addNewEmployee: (data) => dispatch(employee.addNewEmployee(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(EmployeeList);
