import { Box, Typography, Avatar, Grid } from '@material-ui/core';
import getInitials from 'src/utils/getInitials';

// ******* Stylesheet ****** //
import classes from 'src/styles/reports/reports.module.scss';

const EmployeeDetailsView = ({ profile }) => {
  return (
    <Box className={classes.rt__info__box}>
      <Avatar
        // src={emp.avatarUrl}
        sx={{
          mr: 2,
          background: '#2D809F',
          fontSize: 22,
          width: 60,
          height: 60
        }}
      >
        {getInitials(profile.name)}
      </Avatar>
      <Typography className={classes.rt__inner__box__title}>
        {profile.gender === 'male' ? <>Mr. </> : <>Mrs. </>}
        {profile.name}, #{profile.employee_code}
      </Typography>
      <Grid container spacing={5}>
        <Grid item xs={12} sm={6}>
          <Typography className={classes.list__item} gutterBottom>
            <b>Gender: </b>
            {profile.gender}
          </Typography>
          <Typography className={classes.list__item}>
            <b>Email: </b>
            {profile.email}
          </Typography>
        </Grid>
        {/* <Grid item xs={12} sm={6}>
          <Typography className={classes.list__item} gutterBottom>
            <b>Email: </b>
            {profile.email}
          </Typography>
          <Typography className={classes.list__item}>
            <b>Company: </b>
            78946
          </Typography>
        </Grid> */}
      </Grid>
    </Box>
  );
};

export default EmployeeDetailsView;
