import { useState } from "react";
import PropTypes from "prop-types";
import {
  Box,
  IconButton,
  Typography,
  TextField,
  MenuItem,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@material-ui/core";
import AdapterDateFns from "@material-ui/lab/AdapterDateFns";
import LocalizationProvider from "@material-ui/lab/LocalizationProvider";
import DatePicker from "@material-ui/lab/DatePicker";
import CloseIcon from "@material-ui/icons/Close";

// Related to the form 
import * as Yup from "yup";
import { Formik } from "formik";
import moment from "moment";
import { toast } from "react-toastify";
import { gender } from "src/__mocks__/gender";
import { mobileRegEx } from "src/__mocks__/regEx";

const AddNewEmployee = ({
  open, 
  setOpen,
  addNewEmployee
}) => {

  const [date, setDate] = useState(null)

  const handleNewPatient = (values) => {
    values['dob'] = moment(date).format("YYYY-MM-DD")
    //values['location'] = 1
    values['phone_number'] = fixMobileNumber(values.phone_number)
    addNewEmployee(values)
      .then(res => {
        console.log(res)
        toast.success("Employee has been added successfully.")
        setOpen(false)
      })
      .catch(err => {
        console.log(err)
        if(err.data.message === 'Employee code already exists.'){
          toast.error("Employee code already exists.")
        }
        else if(err.data.message.includes("'invalid_phone_number")){
          toast.error("Invalid phone number")
        }else if(err.data.message.includes("accounts_user_email_key")){
          toast.error("Email already exists.")
        }else{
          toast.error("Something went wrong.")
        }
      })
  }

  const fixMobileNumber = (number) => {
    
    let correct;  
    if(String(number).includes('+91')){
      correct = number     
    } else {
      correct = `+91${number}`
    }
    
    return correct;
  }


  return(
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography sx={{fontSize: "1.3rem", fontWeight: "bold"}}>Add New Employee</Typography>
          <IconButton
            onClick={() => setOpen(false)}
          >
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <Formik
        initialValues={{}}
        onSubmit={handleNewPatient}
        validationSchema={Yup.object().shape({
          employee_code: Yup.string().max(16, "Patient ID must be at most 16 characters.").required("Paitent ID is required."),
          name: Yup.string().required("Full Name is required."),
          email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
          phone_number: Yup.string().matches(mobileRegEx, "Invalid Phone Numbr").required("Required"),
          gender: Yup.string().required("Choose gender."),
          address: Yup.string().max(255).required("Address is required.")
        })}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          touched,
          values
        }) => (
          <form onSubmit={handleSubmit}>
            <DialogContent sx={{paddingTop: 0}}>
              <TextField 
                fullWidth
                variant="standard"
                name="employee_code"
                margin="normal"
                label="Employee ID"
                onBlur={handleBlur}
                onChange={handleChange}
                error={Boolean(touched.employee_code && errors.employee_code)}
                helperText={touched.employee_code && errors.employee_code}
                required
              />
              <TextField 
                fullWidth
                variant="standard"
                name="name"
                margin="normal"
                label="Full Name"
                onBlur={handleBlur}
                onChange={handleChange}
                error={Boolean(touched.name && errors.name)}
                helperText={touched.name && errors.name}
                required
              />
              <TextField 
                fullWidth
                variant="standard"
                name="email"
                margin="normal"
                label="Email"
                onBlur={handleBlur}
                onChange={handleChange}
                error={Boolean(touched.email && errors.email)}
                helperText={touched.email && errors.email}
                required
              />
              <TextField 
                fullWidth
                variant="standard"
                name="phone_number"
                margin="normal"
                label="Phone Number"
                onBlur={handleBlur}
                onChange={handleChange}
                error={Boolean(touched.phone_number && errors.phone_number)}
                helperText={touched.phone_number && errors.phone_number}
                required
              />
              <TextField
                fullWidth
                select
                variant="standard"
                name="gender"
                margin="normal"
                label="Gender"
                onBlur={handleBlur}
                onChange={handleChange}
                error={Boolean(touched.gender && errors.gender)}
                helperText={touched.gender && errors.gender}
                required
              >
                {
                  gender.map((item) => (
                    <MenuItem key={item.key} value={item.key} >
                      { item.name }
                    </MenuItem>
                  ))
                }
              </TextField>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label="Date Of Birth"
                  value={date }
                  inputFormat="dd/MM/yyyy"
                  onChange={(newValue) => {
                    setDate(newValue);
                  }}
                  name="dob"
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      required
                      margin="normal"
                      variant="standard"
                    />
                  )}
                />
              </LocalizationProvider>
              <TextField 
                fullWidth
                multiline
                variant="standard"
                name="address"
                margin="normal"
                label="Address"
                onBlur={handleBlur}
                onChange={handleChange}
                rows={3}
                maxRows={3}
                error={Boolean(touched.address && errors.address)}
                helperText={touched.address && errors.address}
                required
              />
              <DialogActions>
                <Button variant="contained" type="submit">
                  Add
                </Button>
              </DialogActions>
            </DialogContent>
          </form>
        )}
        
      </Formik>
    </Dialog>
  )
}

AddNewEmployee.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  // addNewEmployee: PropTypes.func,
}

export default AddNewEmployee;