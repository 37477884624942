import axios from "axios";
import { toast } from "react-toastify";
import {
  GET_DOCTORS,
  GET_DOCTOR_PROFILE,
  GET_ALL_DOCTORS,
  GET_PENDING_DOCTORS,
  GET_EMP_ASSIGNED,
  UPDATE_PAGE_NUM,
} from "../constants/doctor";

const DOCTOR_BASE_URL = `${process.env.REACT_APP_API_URL}/employee/api`;
let headers = { 'Content-Type': 'application/json' };

export const addNewDoctor = (formData) => async (dispatch, getState) => {
  try {
    const token = getState().auth.access_token;
    if (token) {
      headers['Authorization'] = `Bearer ${token}`;
      const response = await axios.post(
        `${DOCTOR_BASE_URL}/create-doctor/`,
        formData,
        { headers }
      );
      if (response.status === 200 || response.status === 201) {
        dispatch(getPendingDoctors())
        dispatch(getOrgDoctors())
        dispatch(getAllDoctors())
        return response;
      }
    }
  } catch (error) {
    if(error.response.data.message === 'Employee code already exists.'){
      toast.error("Employee code already exists.")
    }
    else if(error.response.data.message.includes("'invalid_phone_number")){
      toast.error("Invalid phone number")
    }else if(error.response.data.message.includes("accounts_user_email_key")){
      toast.error("Email already exists.")
    }else if(error.response.data.message.includes("The submitted data was not a file")){
      toast.error("Upload Doctor Medical Registration Certificate");
    }else if(error.response.data.message.includes("doctor with this registration no already exists.")){
      toast.error("Doctor already exists with medical registration number.");
    }
    else{
      toast.error("Something went wrong.")
    }
    throw error;
  }
};

export const doctorInvitation = (data, outer) => async (dispatch, getState) => {
  try {
    const token = getState().auth.access_token;
    if (token) {
      headers['Authorization'] = `Bearer ${token}`;
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/doctor/api/doctor-invite/`,
        data,
        { headers }
      );
      if (response.status === 200 || response.status === 201) {


        if (outer === true) {
          dispatch(getPendingDoctors())
          dispatch(getOrgDoctors())
          dispatch(getAllDoctors())
        }

        return response;
      }
    }
  } catch (error) {
    throw error;
  }
};


export const sendNewMessage = (data) => async (dispatch, getState) => {
  try {
    const token = getState().auth.access_token;
    if (token) {
      headers['Authorization'] = `Bearer ${token}`;
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/organisation/api/doctor-message/`,
        data,
        { headers }
      );
      if (response.status === 200 || response.status === 201) {

        return response;
      }
    }
  } catch (error) {
    if (error.response) {
      toast.error(error.response.data.message)
    } else {
      toast.error("Network Error: Please try again later.")
    }
    throw error;
  }
};

export const getOrgDoctors = () => async (dispatch, getState) => {
  try {
    const token = getState().auth.access_token;
    if (token) {
      headers['Authorization'] = `Bearer ${token}`;
      const response = await axios.get(
        `${DOCTOR_BASE_URL}/doctor-lists/`,
        { headers }
      );
      if (response.status === 200) {
        dispatch({ type: GET_DOCTORS, data: response.data.data })
      }
    }
  } catch (error) {
    throw error;
  }
};

export const getAllDoctors = () => async (dispatch, getState) => {
  try {
    const token = getState().auth.access_token;
    if (token) {
      headers['Authorization'] = `Bearer ${token}`;
      const response = await axios.get(
        `${DOCTOR_BASE_URL}/doctor-all-list/`,
        { headers }
      );
      if (response.status === 200) {
        dispatch({ type: GET_ALL_DOCTORS, data: response.data.data })
      }
    }
  } catch (error) {
    throw error;
  }
};

export const getPendingDoctors = () => async (dispatch, getState) => {
  try {
    const token = getState().auth.access_token;
    if (token) {
      headers['Authorization'] = `Bearer ${token}`;
      const response = await axios.get(
        `${DOCTOR_BASE_URL}/pending-doctor-list/`,
        { headers }
      );
      if (response.status === 200) {
        dispatch({ type: GET_PENDING_DOCTORS, data: response.data.data })
      }
    }
  } catch (error) {
    throw error;
  }
};

export const getDoctorProfile = (data) => async (dispatch, getState) => {
  try {
    const token = getState().auth.access_token;
    if (token) {
      headers['Authorization'] = `Bearer ${token}`;
      const response = await axios.get(
        `${DOCTOR_BASE_URL}/doctor_profile/?${data}`,
        { headers }
      );
      if (response.status === 200) {
        dispatch({ type: GET_DOCTOR_PROFILE, data: response.data.data })
      }
    }
  } catch (error) {
    throw error;
  }
};

export const getAssignedEmployee = (query) => async (dispatch, getState) => {
  try {
    const token = getState().auth.access_token;
    if (token) {
      headers['Authorization'] = `Bearer ${token}`;
      const response = await axios.get(
        `${DOCTOR_BASE_URL}/assigned-employee-list/${query}`,
        { headers }
      );
      if (response.status === 200) {
        dispatch({ type: GET_EMP_ASSIGNED, data: response.data.data })
      }
    }
  } catch (error) {
    throw error;
  }
};

export const updatedPageNum = (data) => (dispatch, getState) => {
  if (data) {
    dispatch({ type: UPDATE_PAGE_NUM, data: data })
  }
}