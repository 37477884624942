import { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Box, Container, Grid, Typography } from '@material-ui/core';
import moment from 'moment';

// #React-redux
import { connect } from 'react-redux';
import { dashboard } from 'src/redux/actions';

// Views
import Last10Test from 'src/components/dashboard/Last10Test';
import HighRiskPeople from 'src/components/dashboard/HighRiskPeople';
import NoFTChartView from 'src/components/dashboard/NoFTChartView';
import AllStatsView from 'src/components/dashboard/allStatsView';

// Stylesheet
import classes from 'src/styles/dashboard/dashboard.module.scss';

const Dashboard = ({
  fetchAllStats,
  allStats,
  fetchNOFTestGraph,
  nofTestGraph,
  fetchAllOnHighRisk,
  allOnHighRisk,
  fetchLastTenTest,
  lastTenTest,
}) => {


  // Fetching stats & Required data that we have to display on Dahsboard View 
  useEffect(() => {
    fetchAllStats();
    fetchAllOnHighRisk();
    fetchNOFTestGraph();
    fetchLastTenTest();
  }, []);

  return (
    <>
      <Helmet>
        <title>Dashboard | TraceLyfe</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.secondary',
          minHeight: '100%',
          py: 3
        }}
      >
        <Container maxWidth={false} className={classes.root__container}>
          <Box className={classes.header__container}>
            <Box>
              <Typography className={classes.title}>Overview</Typography>
            </Box>
            <Box>
              <Typography className={classes.date__title}>
                <span>Last Updated-</span>
                {moment().format('lll')}
              </Typography>
            </Box>
          </Box>
          <Grid container spacing={3}>

            {/**
             * All Stats View mentioned below -
             * Total Test Done
             * People at High Risk
             * People at Moderate Risk
             * People at Low Risk
             */}
            {allStats && <AllStatsView allStats={allStats} />}

            <Grid item lg={9} md={12} xl={9} xs={12}>
              {
                nofTestGraph &&
                <NoFTChartView graphData={nofTestGraph} />
              }
            </Grid>
            <Grid item lg={3} md={12} xl={3} xs={12}>
              {
                allOnHighRisk &&
                <HighRiskPeople people={allOnHighRisk} />
              }
            </Grid>
            <Grid item lg={12} md={12} xl={12} xs={12}>
              {
                lastTenTest &&
                <Last10Test employee={lastTenTest} />
              }
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

const mapStateToProps = (state) => ({
  allStats: state.dashboard.allStats,
  nofTestGraph: state.dashboard.nofTestGraph,
  allOnHighRisk: state.dashboard.allOnHighRisk,
  lastTenTest: state.dashboard.lastTenTest,
});

const mapDispatchToProps = (dispatch) => ({
  fetchAllStats: () => dispatch(dashboard.fetchAllStats()),
  fetchNOFTestGraph: () => dispatch(dashboard.fetchNOFTestGraph()),
  fetchAllOnHighRisk: () => dispatch(dashboard.fetchAllOnHighRisk()),
  fetchLastTenTest: () => dispatch(dashboard.fetchLastTenTest())
});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
