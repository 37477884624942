import { useEffect } from "react";
import { Helmet } from "react-helmet";
import {
  Box,
  Container,
  Typography
} from "@material-ui/core";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import { useNavigate, useParams } from "react-router-dom";

/* ======= React Redux ======== */
import { useDispatch, useSelector } from "react-redux";
import { doctor } from "src/redux/actions";

/* ======= Views ======== */
import Doctor from "src/components/doctorProfile/doctor";

/* ======= Stylesheet ======= */
import classes from "src/styles/ourDoctor/ourDoctor.module.scss";

const DoctorProfile = () => {
  
  const navigate = useNavigate();
  const { id, name, org } = useParams();

  /**
   * 
   * Using Redux hooks only because 
   * we have to call only one action here
   * otherwise we will have to use connect to organize the work
   * 
   */

  const dispatch = useDispatch();
  const doctorProfile = useSelector(state => state.doctor.doctorProfile);
  const assignedEmp = useSelector(state => state.doctor.assignedEmp);
  const pageNum = useSelector(state => state.doctor.pageNum);

  useEffect(() => {
    if(!id && !name) return;

    if(String(org) === "has"){
      const query = `doctor_id=${id}&types=ourdoc`
      dispatch(doctor.getDoctorProfile(query))
    } else if(String(org) === "pending"){
      const query = `doctor_id=${id}&types=pending`
      dispatch(doctor.getDoctorProfile(query))
    } else if(String(org) === "other"){
      const query = `doctor_id=${id}&types=recomdoc`
      dispatch(doctor.getDoctorProfile(query))
    }

  },[id])

  useEffect(() => {
    if(pageNum){
      let query = `?page=${pageNum}`
      dispatch(doctor.getAssignedEmployee(query))
    }
  },[pageNum])

  return(
    <>
      <Helmet>
        <title>Doctor Profile | TraceLyfe</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.secondary',
          minHeight: '100%',
          py: 3
        }}
      >
        <Container maxWidth={false} className={classes.od_prof__root}>
          <Box className={classes.od_prof__header}>
            <Box 
              className={classes.od_prof__breadcrumbs}
              onClick={() => navigate("/app/our-doctor")}  
            >
              <KeyboardBackspaceIcon />
              <Typography>Tracelyfe Recommended Doctor's</Typography>
            </Box>

            {/* ... for further  */}
          </Box>
          <Box mt={3}>
            {
              doctorProfile && 
              <Doctor profile={doctorProfile} assignedEmp={assignedEmp} />
            }
          </Box>
        </Container>
      </Box>
    </>
  )
}

export default DoctorProfile;