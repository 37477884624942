import { useState, useEffect} from "react";
import { Helmet } from "react-helmet";
import {
  Box,
  Container,
  Typography,
  Button,
  Tab,
  Tabs
} from "@material-ui/core";
import LocationOnIcon from '@material-ui/icons/LocationOn';
import { useNavigate, createSearchParams } from "react-router-dom";

/* ========= Tools ========== */
import TabPanel from "src/utils/tabPanel";
import a11yProps from "src/utils/a11yProps";
import getParameterByName from "src/utils/getSearchParams";

/* ========= React Redux ========== */
import { useDispatch, useSelector } from "react-redux";
import { doctor } from "src/redux/actions";

/* ======= Views ====== */
import AddNewDoctorForm from "src/components/ourDoctor/addNewDoctorForm";
import DoctorListView from "src/components/ourDoctor/doctorList";

/* ======= Stylesheet ======= */

import classes from "src/styles/ourDoctor/ourDoctor.module.scss"

const OurDoctor = () => {

  const dispatch = useDispatch();
  const orgDoctor = useSelector(state => state.doctor.orgDoctor);
  const allDoctor = useSelector(state => state.doctor.allDoctor);
  const pendingDoctor = useSelector(state => state.doctor.pendingDoctor)
  const query = getParameterByName("tab");
  const navigate = useNavigate();

  const [value, setValue] = useState(0);
  const [addNew, SetAddNew ] = useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    navigate({
      pathname: window.location.pathname,
      search: `?${createSearchParams({
          tab: newValue
      })}`
    })
  };

  // Kind of callback function for tab state

  useEffect(() => {
    if(query === null){
      navigate({
        pathname: window.location.pathname,
        search: `?${createSearchParams({
            tab: value
        })}`
      })
    } else {

      /**
       *  
       * We have tab number for now 4 in [0, 1, 2, 3] format
       * Now Check if tab number is greater than tab length
       * 
       */
      if(Number(query) >= 4){

        // If tab number is greater than tab length 
        // push the tab to the 0.
        navigate({
          pathname: window.location.pathname,
          search: `?${createSearchParams({
              tab: 0
          })}`
        })
        setValue(0)

      } else {
        setValue(Number(query))
      }
    }
  },[value])

  // Fetch Doctor List 

  useEffect(() => {
    dispatch(doctor.getOrgDoctors()) //Org Doctor
    dispatch(doctor.getAllDoctors()) //All Doctor
    dispatch(doctor.getPendingDoctors()) //Pending Doctor
  },[])


  return (
    <>
      <Helmet>
        <title>Our Doctor | TraceLyfe</title>
      </Helmet> 
      <Box
        sx={{
          backgroundColor: 'background.secondary',
          minHeight: '100%',
          py: 3
        }}
      >
        <Container maxWidth={false} className={classes.od__root}>
          <Box className={classes.od__header} >
            <Typography className={classes.od__title}>
              { addNew ? "Add New Doctor" : "Our Doctor"}
            </Typography>
            <Typography
              sx={{
                display: "flex",
                alignItems: "center",
                color: "gray"
              }}
            >
              <LocationOnIcon 
                sx={{
                  marginRight: 1
                }}
              />
              New Delhi
            </Typography>
          </Box>
          <Box mt={3}>
            <Tabs
              value={value} 
              onChange={handleChange} 
              aria-label="report tabs" 
              variant="scrollable"
              className={classes.tab__root}
            >
              <Tab 
                label="Our Doctor" 
                {...a11yProps(0)} 
                classes={{
                  root: classes.tabs,
                  selected: classes.tab__selected
                }} 
              />
              <Tab 
                label="Add New Doctor" 
                {...a11yProps(1)} 
                classes={{
                  root: classes.tabs,
                  selected: classes.tab__selected
                }} 
              />
              <Tab 
                label="Pending Request" 
                {...a11yProps(2)} 
                classes={{
                  root: classes.tabs,
                  selected: classes.tab__selected
                }} 
              />
              <Tab 
                label="Recommended Doctor" 
                {...a11yProps(3)} 
                classes={{
                  root: classes.tabs,
                  selected: classes.tab__selected
                }} 
              />


              {/* Just to show full width border  */}

              {/* <span className={classes.tab__extraBorder}>{" "}</span>   */}

            </Tabs>
            <TabPanel value={value} index={0}>
              {
                orgDoctor && 
                <DoctorListView totalDoctor={orgDoctor} />
              }
            </TabPanel>
            <TabPanel value={value} index={1}>
              <AddNewDoctorForm showOurDoctor={SetAddNew} />
            </TabPanel>
            <TabPanel value={value} index={2}>
              {
                pendingDoctor && 
                <DoctorListView totalDoctor={pendingDoctor} pending={true} />
              }
            </TabPanel>
            <TabPanel value={value} index={3}>
              {
                allDoctor && 
                <DoctorListView totalDoctor={allDoctor} recommended={true} />
              }
            </TabPanel>
          </Box>
        </Container>
      </Box>  
    </>
  )
}

export default OurDoctor;