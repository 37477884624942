import {
  GET_EMPLOYEE_DETAIL,
  LAST_RECENT_TESTS,
  FETCH_VITALS_TABLE_DATA,
  FETCH_VITALS_GRAPH_DATA,
  FETCH_EMPLOYEE_REPORT,
  LOCATION_CHANGE,
} from "../constants/report";

const initialState = {
  employeeProfile: null,
  lastTests: null,
  bpReadings: null,
  bpGraph: null,
  tempReadings: null,
  tempGraph: null,
  glucoseReadings: null,
  glucoseGraph: null,
  pulseReadings: null,
  pulseGraph: null,
  weightReadings: null,
  weightGraph: null,
  spo2Readings: null,
  spo2Graph: null,
  employeeReport: {
    previous: null,
    next: null,
    results: []
  }
}

export default function report(state = initialState, action){
  switch(action.type){

    case GET_EMPLOYEE_DETAIL:
      return { ...state, employeeProfile: action.data }

    case LAST_RECENT_TESTS:
      return { ...state, lastTests: action.data }

    case FETCH_VITALS_TABLE_DATA:
      return {
        ...state,
        bpReadings: action.data.blood_pressure,
        tempReadings: action.data.temperature,
        glucoseReadings: action.data.glucose,
        pulseReadings: action.data.pulse,
        weightReadings: action.data.weight,
        spo2Readings: action.data.oxygen_saturation
      }

    case FETCH_VITALS_GRAPH_DATA:
      return {
        ...state,
        bpGraph: action.data.blood_pressure,
        tempGraph: action.data.temperature,
        glucoseGraph: action.data.glucose,
        pulseGraph: action.data.pulse,
        weightGraph: action.data.weight,
        spo2Graph: action.data.oxygen_saturation
      }

    case FETCH_EMPLOYEE_REPORT:
      return { 
        ...state, 
        employeeReport: {
          previous: action.data.previous,
          next: action.data.next,
          // keeping old data because we use infinite scroll for pagination
          results: [...state.employeeReport.results, ...action.data.results]
        }  
      }

    case LOCATION_CHANGE: 
      return initialState; 
       
    default:
      return state;

  }
}