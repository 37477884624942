import { Outlet } from 'react-router-dom';
import { styled } from '@material-ui/core/styles';
import LocationNavbar from './LocationNavbar';

const LocationLayoutRoot = styled('div')(
  ({ theme }) => ({
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%'
  })
);

const LocationLayoutWrapper = styled('div')({
  display: 'flex',
  flex: '1 1 auto',
  overflow: 'hidden',
  // paddingTop: 64
});

const LocationLayoutContainer = styled('div')({
  display: 'flex',
  flex: '1 1 auto',
  overflow: 'hidden'
});

const LocationLayoutContent = styled('div')({
  flex: '1 1 auto',
  height: '100%',
  overflow: 'auto'
});

const LocationLayout = () => (
  <LocationLayoutRoot>
    {/* <MainNavbar /> */}
    <LocationLayoutWrapper>
      <LocationLayoutContainer>
        <LocationLayoutContent>
          <Outlet />
        </LocationLayoutContent>
      </LocationLayoutContainer>
    </LocationLayoutWrapper>
  </LocationLayoutRoot>
);

export default LocationLayout;
