import React from "react";
import { Box, Grid, Typography } from "@material-ui/core";
import AreaChartView from "src/__charts__/AreaChart";
import GetReadingTable from "src/utils/getReading";

// StyleSheet

import styles from "src/styles/reports/chart.module.scss";

function SPO2View({ tableData, graphData }) {
  return (
    <Grid container className={styles.ct__root}>
      <Grid item sm={12} lg={5} className={styles.ct__left}>
        <Box className={styles.ct__left__inner}>
          <Typography className={styles.ct__left__title}>Oxygen Saturation</Typography>
          <Typography className={styles.ct__left__reading}>
            { tableData && tableData.length !== 0 && tableData[0].reading1.toFixed(2) }%
          </Typography>
        </Box>
        <Box sx={{ padding: '20px 0px'}}>
          {
            tableData && tableData.length !== 0 
            ?
            <GetReadingTable rows={tableData} showSpO2Indicator={true} />
            :
            <Box
              sx={{
                minHeight: "200px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
              }}
            >
              <Typography>No Data Available</Typography>
            </Box>
          }
        </Box>
      </Grid>
      <Grid item sm={12} lg={7} className={styles.ct__right}>
        <AreaChartView graphData={graphData} />
      </Grid>
    </Grid>
  )
}

export default SPO2View
