import {
  GET_DOCTORS,
  GET_DOCTOR_PROFILE,
  GET_ALL_DOCTORS,
  GET_PENDING_DOCTORS,
  GET_EMP_ASSIGNED,
  UPDATE_PAGE_NUM,
} from "../constants/doctor";

const initialState = {
  orgDoctor: null,
  doctorProfile: null,
  allDoctor: null,
  pendingDoctor: null,
  assignedEmp: {
    prev: null,
    next: null, 
    count: null,
    results: []
  },
  pageNum: 1,
}

export default function doctor( state = initialState, action ){
  switch(action.type){

    case GET_DOCTORS:
      return { ...state, orgDoctor: action.data }

    case GET_DOCTOR_PROFILE:
      return { ...state, doctorProfile: action.data }

    case GET_ALL_DOCTORS:
      return { ...state, allDoctor: action.data }

    case GET_PENDING_DOCTORS:
      return { ...state, pendingDoctor: action.data }

    case GET_EMP_ASSIGNED:
      return { 
        ...state,
        assignedEmp: {
          prev: action.data.previous,
          next: action.data.next,
          count: action.data.count,
          results: [...action.data.results]
        }
      }

    case UPDATE_PAGE_NUM:
      return {...state, pageNum: action.data}

    default:
      return state;
  }
}