import { combineReducers } from "redux";
import auth from "./auth";
import employee from "./employee";
import dashboard from "./dashboard";
import report from "./report";
import doctor from "./doctor";

export default combineReducers({
  auth,
  employee,
  dashboard,
  report,
  doctor
})