import {
  Box,
  Card,
  CardContent,
  CardHeader,
} from '@material-ui/core';

// Chart View
import EmployeeTestChart from 'src/__charts__/EmployeeTestChart';

const NoFTChartView = ({graphData, ...props}) => {

  return (
    <Card 
      sx={{
        boxShadow: '0px 2px 12px rgb(21 21 22 / 4%) !important',
        border: '1px solid #E6EAEE',
        borderRadius: "13px"
      }}
      {...props}
    >
      <CardHeader
        title="Number Of Test"
      />
      <CardContent>
        <Box
          sx={{
            height: 400,
            position: 'relative'
          }}
        >
          <EmployeeTestChart chartData={graphData} />
        </Box>
      </CardContent>
    </Card>
  );
};

export default NoFTChartView;
