import axios from 'axios';

// @react-redux
import store from 'src/redux/store';
import { auth } from 'src/redux/actions';

export default function axiosInterceptorHandler() {
  /**
   * Axios Interceptor to handle refresh token
   */

  let isAlreadyFetching = false;
  let subscriber = [];

  function onAccessTokenFetched(access_token) {
    subscriber = subscriber.filter((callback) => callback(access_token));
  }

  function addSubscriber(callback) {
    subscriber.push(callback);
  }

  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      const {
        config,
        response: { status }
      } = error;
      const originalRequest = config;

      if (status === 401) {
        if (!isAlreadyFetching) {
          isAlreadyFetching = true;
          store.dispatch(auth.refreshToken()).then((res) => {
            isAlreadyFetching = false;
            let state = store.getState().auth;
            let access_token = state.access_token;
            onAccessTokenFetched(access_token);
          });
        }

        const retryOriginalRequest = new Promise((resolve) => {
          addSubscriber((access_token) => {
            originalRequest.headers.Authorization = `Bearer ${access_token}`;
            resolve(axios(originalRequest));
          });
        });
        return retryOriginalRequest;
      }
      return Promise.reject(error);
    }
  );
}
