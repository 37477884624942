import {
  GET_EMPLOYEE,
} from "../constants/employee";

const initialState = {
  totalEmployee: null,
}

export default function employee(state = initialState, action){
  switch(action.type){
    case GET_EMPLOYEE:
      return { ...state, totalEmployee: action.data }

    default: 
      return state;
  }
}