import { 
  Box, 
  Typography, 
  Avatar, 
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText, 
  Divider
} from '@material-ui/core';
import moment from 'moment';
import getInitials from 'src/utils/getInitials';

// ******* Stylesheet ****** //
import classes from "src/styles/employee/employeeInfo.module.scss";

const EmployeeProfileDetailsView = ({ profile }) => {
  return (
    <Box className={classes.ei__info__box} mt={3}>
      <Grid container>
        <Grid 
          item 
          xs={12}
          sm={12} 
          md={2} 
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <Avatar
            // src={emp.avatarUrl}
            sx={{
              mr: 2,
              background: '#2D809F',
              fontSize: 22,
              width: 90,
              height: 90
            }}
          >
            {getInitials(profile.name)}
          </Avatar>
        </Grid>
        <Grid item xs={12} sm={12} md>
          <List>
            <ListItem sx={{padding: "0px 16px"}}>
              <ListItemAvatar>
                <Typography className={classes.list__item}>
                  <b>Name: </b>
                </Typography>
              </ListItemAvatar>
              <ListItemText>
                <Typography className={classes.list__item}>
                  {profile.name}
                </Typography>
              </ListItemText>
            </ListItem>
            <ListItem sx={{padding: "0px 16px"}}>
              <ListItemAvatar>
                <Typography className={classes.list__item}>
                  <b>Gender: </b>
                </Typography>
              </ListItemAvatar>
              <ListItemText>
                <Typography className={classes.list__item}>
                  {profile.gender}
                </Typography>
              </ListItemText>
            </ListItem>
            <ListItem sx={{padding: "0px 16px"}}>
              <ListItemAvatar>
                <Typography className={classes.list__item}>
                  <b>Age: </b>
                </Typography>
              </ListItemAvatar>
              <ListItemText>
                <Typography className={classes.list__item}>
                  {moment().diff(profile.dob, "years")} Years
                </Typography>
              </ListItemText>
            </ListItem>
          </List>
        </Grid>
        <Divider flexItem orientation="vertical" />
        <Grid item xs={12} sm={12} md>
          <List>
            <ListItem sx={{padding: "0px 16px"}}>
              <ListItemAvatar>
                <Typography className={classes.list__item}>
                  <b>Employee ID: </b>
                </Typography>
              </ListItemAvatar>
              <ListItemText>
                <Typography className={classes.list__item}>
                  {profile.employee_code}
                </Typography>
              </ListItemText>
            </ListItem>
            <ListItem sx={{padding: "0px 16px"}}>
              <ListItemAvatar>
                <Typography className={classes.list__item}>
                  <b>Email: </b>
                </Typography>
              </ListItemAvatar>
              <ListItemText>
                <Typography className={classes.list__item}>
                  {profile.email}
                </Typography>
              </ListItemText>
            </ListItem>
            <ListItem sx={{padding: "0px 16px"}}>
              <ListItemAvatar>
                <Typography className={classes.list__item}>
                  <b>Address: </b>
                </Typography>
              </ListItemAvatar>
              <ListItemText>
                <Typography className={classes.list__item}>
                  {profile.address}
                </Typography>
              </ListItemText>
            </ListItem>
          </List>
        </Grid>
      </Grid>
    </Box>
  );
};

export default EmployeeProfileDetailsView;
