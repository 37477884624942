
import { useState, useEffect, useRef, useCallback } from "react";
import { useParams } from "react-router-dom";

/* ============ Redux ============ */
import { useDispatch, useSelector } from "react-redux";
import { report } from "src/redux/actions";

import {
  Grid,
  Typography,
  Box,
  CircularProgress
} from "@material-ui/core";
import PermMediaIcon from '@material-ui/icons/PermMedia';

/* ============ Toastify => to show message :) ============ */
import { toast } from "react-toastify";

import RenderFiles from "./renderFile";

/* ============ Stylesheet ============ */
import classes from "src/styles/reports/reports.module.scss";

const EmployeeReportView = ({
  pageNum,
  setPageNum,
  loading
}) => {

  // Init Dispatch 
  const dispatch = useDispatch();
  const reports = useSelector(state => state.report.employeeReport)
  const { id } = useParams(); // Fetch Employee Profile

  // // States
  // const [pageNum, setPageNum] = useState(1);
  // const [ loading, setLoading] = useState(false);

  /**
   * 
   * Observing last node in the rendered node 
   * after getting last we're making new request to the backend 
   * 
   * ============ TODO ============
   * Have to make a intersectionObserver hook :)
   * Also check a better render method to check lastEl in node
   * 
   */

  const observer = useRef();
  const lastEl = useCallback(node => {
    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting && Boolean(reports.next)) {
        setPageNum(pageNum => pageNum + 1)
      } else {
        console.log("No next url")
      }
    })
    if (node) observer.current.observe(node)
  }, [reports])

  //  useEffect(() => {
  //   if(!hasMore){
  //     setLoading(true)
  //     dispatch(report.fetchEmployeeReports(`profile_id=${id}&page=${pageNum}`))
  //      .then(res => setLoading(false))
  //   }
  //  }, [id, pageNum])

  const viewReport = (item) => {
    if (id && item) {
      const queryData = `profile_id=${id}&report_id=${item.id}`;
      dispatch(report.viewDownloadReport(queryData))
        .then(res => {
          let url = res.data.data;
          window.open(url, '_blank')
        })
    }
  }

  const downloadURL = (item) => {
    if (id && item) {
      const queryData = `profile_id=${id}&report_id=${item.id}`;
      dispatch(report.viewDownloadReport(queryData))
        .then(res => {
          let fetchUrl = res.data.data;
          const fileName = new URL(fetchUrl).pathname.split('/').pop();
          fetch(fetchUrl, { method: 'GET' })
            .then(response => response.blob())
            .then(blob => {
              toast.success("Downloading started....")
              var url = window.URL.createObjectURL(blob);
              var a = document.createElement('a');
              a.href = url;
              a.download = `${fileName}`;
              document.body.appendChild(a);
              a.click();
              setTimeout(
                _ => { window.URL.revokeObjectURL(url); },
                40000); // Revoked object URL in 40 sec
              a.remove();
            })
            .catch(err => {
              console.log(err)
              toast.error("Network Error: Failed to fetch")
            })
        })
    }
  }
  return (
    <Grid container spacing={2}>
      {
        reports && reports.results.length > 0
          ?
          <>
            {
              reports.results.map((item, index) => {
                if (reports.results.length === index + 1) {
                  return (
                    <Grid
                      item
                      lg={3}
                      md={6}
                      sm={6}
                      xs={12}
                      key={index}
                      ref={lastEl}
                    >
                      <RenderFiles
                        item={item}
                        classes={classes}
                        viewReport={viewReport}
                        downloadURL={downloadURL}
                      />
                    </Grid>
                  )
                } else {
                  return (
                    <Grid
                      item
                      lg={3}
                      md={6}
                      sm={6}
                      xs={12}
                      key={index}
                    >
                      <RenderFiles
                        item={item}
                        classes={classes}
                        viewReport={viewReport}
                        downloadURL={downloadURL}
                      />
                    </Grid>
                  )
                }
              })
            }
            {
              loading
                ?
                <Box display="flex" justifyContent="center" alignItems="center" p={6} width="100%" >
                  <CircularProgress color="inherit" size={16} disableShrink />
                  <Typography sx={{ ml: 1.2 }} >Loading....</Typography>
                </Box>
                :
                ""
            }
          </>
          :
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              height: 300,
              flexDirection: "column"
            }}
          >
            <PermMediaIcon
              sx={{
                width: 70,
                height: 70,
                color: "gray",
                pb: 2
              }}
            />
            <Typography
              sx={{
                fontWeight: 500,
                color: "gray",
                letterSpacing: "0.8px",
                px: 4,
                textAlign: "center"
              }}
            >
              No Report have been uploaded by the employee yet.
            </Typography>
          </Box>
      }
    </Grid>
  );
};

export default EmployeeReportView;
