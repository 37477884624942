import {
  Box,
  Typography,
} from "@material-ui/core";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";

/* ======= Stylesheet ======= */
import classes from "src/styles/ourDoctor/ourDoctor.module.scss";

export default function LeftView({profile}) {
  return (
    <Box className={classes.od_prof_left__root}>
      <Box className={classes.od_prof_left__box} >
        <Box className={classes.od_prof_left__imgBox}>
          <img 
            src={
              profile.profile_pic
              ?
              profile.profile_pic
              :
              "/static/images/avatars/avatar_4.png"
            }
          />
        </Box>
        <Box className={classes.od_prof_left__detailBox} mt={2}>
          <Typography className={classes.title}>{profile.name}</Typography>
          <Box className={classes.desc__box} mt={2}>
            <VerifiedUserIcon />
            <Typography>Tracelyfe Verified</Typography>
          </Box>
        </Box>
      </Box>
      <Box mt={3} className={classes.od_prof_left__box} >
        <iframe 
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3508.278474253813!2d77.03732951511665!3d28.44102068249374!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d18695c471015%3A0xa44b7b144935cae4!2sSAS%20Tower!5e0!3m2!1sen!2sin!4v1634802770176!5m2!1sen!2sin" 
          width="100%" 
          height="200" 
          style={{border: 0, borderRadius: 13}} 
          allowFullScreen="" 
          loading="lazy"
        ></iframe>
        <Box className={classes.od_prof_left__detailBox} mt={2}>
          <Typography className={classes.sub__title}>SAS Tower <span style={{fontSize: 14}}>(Static)</span></Typography>
          <Box className={classes.desc__box} mt={2}>
            <Typography sx={{textTransform: "capitalize"}}>{profile.address}</Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}