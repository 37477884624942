import React from 'react';
// import { makeStyles } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Chip from "@material-ui/core/Chip";
import moment from 'moment';

const useStyles = makeStyles({
  root: {
    maxHeight: 350,
    "&::-webkit-scrollbar": {
      width: "3px"
    }
  },
  table: {
    // minWidth: 650,
  },
});


export default function GetReadingTable({
  rows, 
  showBPIndicator,
  showTempIndicator, 
  showWeightIndicator,
  showPulseIndicator,
  showGlucoseIndicator,
  showSpO2Indicator,
}) {
  const classes = useStyles();

  return (
    <TableContainer className={classes.root}>
      <Table className={classes.table} aria-label="simple table" stickyHeader>
        <TableHead>
          <TableRow>
            {/* <TableCell>Parameter</TableCell> */}

            <TableCell align="left">Date {'&'} Time</TableCell>
            <TableCell align="center">Reading</TableCell>
            <TableCell align="right">Status</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.vital_name}>
              {/* <TableCell component="th" scope="row" sx={{textTransform: "capitalize"}}>
                {
                  row.vital_name === "blood_pressure" 
                  ? "Blood Pressure" 
                  : row.vital_name === "oxygen_saturation"
                  ? "Oxygen Saturation"
                  : row.vital_name
                }
                { showBPIndicator ? <>Blood Pressure</> : "" }
                { showTempIndicator ? <>Temperature</> : ""}
                { showWeightIndicator ? <>Weight</> : "" }
                { showPulseIndicator ? <>Pulse</> : ""}
                { showGlucoseIndicator ? <>Blood Glucose</> : "" }
                { showSpO2Indicator ? <>Oxygen Saturation</> : ""}
              </TableCell> */}
              <TableCell align="left">{moment(row.created_on).format("lll")}</TableCell>
              <TableCell align="center">
                {row.reading2 ? <>{row.reading1} / {row.reading2} </> : row.reading1} 
                { showBPIndicator ? <> mmHg</> : "" }
                { showTempIndicator ? <><sup>o</sup>C</> : ""}
                { showWeightIndicator ? <> Kg</> : "" }
                { showPulseIndicator ? <> bpm</> : ""}
                { showGlucoseIndicator ? <> mg/dL</> : "" }
                { showSpO2Indicator ? <>%</> : ""}
              </TableCell>
              <TableCell align="right">
                <Chip 
                  label={row.status}  
                  size="small"
                  sx={{
                    background: row.status === "alert" 
                      ?  "red" 
                      : row.status === "moderate"
                      ? "#F47920"
                      : "limegreen",

                    color: "#fff",
                    textTransform: "capitalize"
                  }} 
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
