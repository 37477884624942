import { Typography, IconButton } from '@material-ui/core';
import moment from 'moment';

/* ============ Feather Icons ============ */
import { Folder, Download, Eye } from 'react-feather';

export default function RenderFiles({
  item,
  viewReport,
  downloadURL,
  classes
}) {
  return (
    <div className={classes.report__files__box}>
      <Folder style={{ width: 40, height: 40 }} />
      <Typography
        gutterBottom
        variant="h6"
        className={classes.file__title}
        sx={{ fontSize: 16 }}
      >
        {item.name}
      </Typography>
      <Typography
        gutterBottom
        sx={{
          fontSize: 14
        }}
      >
        {item.summary}
      </Typography>
      <Typography gutterBottom variant="h6" className={classes.file__title}>
        {moment(item.updated_on).format('ll')}
      </Typography>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <IconButton onClick={() => viewReport(item)}>
          <Eye style={{ width: 18 }} />
        </IconButton>
        <IconButton onClick={() => downloadURL(item)}>
          <Download style={{ width: 18 }} />
        </IconButton>
      </div>
    </div>
  );
}
