import { useState, useRef } from "react";
import {
  Box,
  Grid,
  TextField,
  Typography,
  Button,
  Select,
  MenuItem,
  IconButton
} from "@material-ui/core";

import CloseIcon from '@material-ui/icons/Close';
import AttachmentIcon from '@material-ui/icons/Attachment';
import "react-datepicker/dist/react-datepicker.css";

import {
  FilePlus as AddFileIcon
} from "react-feather";
import DatePicker from "react-datepicker";

import { useNavigate, createSearchParams } from "react-router-dom";

import { mobileRegEx } from "src/__mocks__/regEx";
/* ========= React Redux ========== */
import { useDispatch } from "react-redux";
import { doctor } from "src/redux/actions";

/* ========= Related to the form ========= */
import * as Yup from "yup";
import { Formik, useFormikContext, useField } from "formik";
import moment from "moment";
import { toast } from "react-toastify";

/* ========= Stylesheet ========= */
import classes from "src/styles/ourDoctor/ourDoctor.module.scss"

const AddNewDoctorForm = ({ showOurDoctor }) => {

  const [date, setDate] = useState(null)
  const [selected, setSelected] = useState(null);
  const [isFilePicked, setFilePicked] = useState(false);
  const fileRef = useRef();
  const dispatch = useDispatch();
  const navigate = useNavigate();


  // Media Handler

  const handleMedia = (event) => {
    if (event) {
      setSelected(event.target.files[0])
      setFilePicked(true)
    }
  }

  const onRemove = () => {
    setFilePicked(false)
    setSelected(null)
  }

  const handleNewDoctor = (values) => {
    //  if (selected === null) return toast.error("Please upload your certificate.")
    console.log("Values \n\n", selected)
    if (values) {
      if(values["phone_number"].length === 10 && selected !== null){
      const formData = new FormData();
      var phone = '';
      phone = `+91${values["phone_number"]}`;
      formData.append("document", selected)
      formData.append("name", values["name"])
      formData.append("employee_code", values["employee_code"])
      formData.append("gender", values["gender"])
      formData.append("address", values["address"])
      formData.append("experience", values["experience"])
      formData.append("degree", values["degree"])
      formData.append("email", values["email"])
      formData.append("registration_no", values["registration_no"])
      formData.append("dob", values["dob"])
      formData.append("phone_number", phone)

      dispatch(doctor.addNewDoctor(formData))
        .then(res => {
          toast.success("Doctor has been added successfully.")
          showOurDoctor(false)
          navigate('/doctorListView')
        })
      // .catch(err => {
      //   toast.error("Network Error: Please try again later.")
      // })
      }
      else{
        if (values["phone_number"].length !== 10 ){
          toast.error("Phone number not 10 digits.")
        }
        if (selected === null){
          toast.error("Upload Doctor Medical Registration Certificate")
        }
      }
    } else {
      toast.error("Please fill the required field.")
    }
  }

  const fixMobileNumber = (number) => {

    let correct;
    if (String(number).includes('+91')) {
      correct = number
    } else {
      correct = `+91${number}`
    }

    return correct;
  }


  return (
    <Box p={2}>
      <Formik
        initialValues={{}}
        onSubmit={handleNewDoctor}
        validationSchema={Yup.object().shape({
          employee_code: Yup.string().required("Employee code is required."),
          name: Yup.string().required("Full Name is required."),
          dob: Yup.string().required("Dob is required"),
          gender: Yup.string().required("Gender is required"),
          address: Yup.string().required("Address is required"),
          phone_number: Yup.string().matches(mobileRegEx, "Invalid Phone Numbr").required("PhoneNumber is required"),
          degree: Yup.string().required("Degree is required."),
          experience: Yup.string().required("Experience is required."),
          email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
          registration_no: Yup.string().required("Registration number is required.")
        })}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          touched,
          values
        }) => (
          <form onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={6}>
                <Box mt={2}>
                  <Typography>Doctor Code <span style={{ color: 'red' }}>*</span></Typography>
                  <TextField
                    fullWidth
                    name="employee_code"
                    margin="normal"
                    placeholder="Doctor code "
                    onBlur={handleBlur}
                    onChange={handleChange}
                    className={classes.od__textField}
                    error={Boolean(touched.employee_code && errors.employee_code)}
                    helperText={touched.employee_code && errors.employee_code}
                    required
                  />
                </Box>
                <Box mt={2}>
                  <Typography>Name of the doctor <span style={{ color: 'red' }}>*</span></Typography>
                  <TextField
                    fullWidth
                    name="name"
                    margin="normal"
                    placeholder="Dr. "
                    onBlur={handleBlur}
                    onChange={handleChange}
                    className={classes.od__textField}
                    error={Boolean(touched.name && errors.name)}
                    helperText={touched.name && errors.name}
                    required
                  />
                </Box>
                <Box mt={2}>
                  <Typography>Gender <span style={{ color: 'red' }}>*</span></Typography>
                  <Select
                    displayEmpty
                    inputProps={{ 'aria-label': 'Without label' }}
                    fullWidth
                    name="gender"
                    margin="normal"
                    placeholder="Gender"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    className={classes.od__textField}
                    error={Boolean(touched.gender && errors.gender)}
                    helperText={touched.gender && errors.gender}
                    required
                  >
                    <MenuItem value="male">Male</MenuItem>
                    <MenuItem value="female">Female</MenuItem>
                    <MenuItem value="other">Other</MenuItem>
                  </Select>
                </Box>
                <Box mt={2}>
                  <Typography>Date Of Birth <span style={{ color: 'red' }}>*</span></Typography>

                  <TextField
                    fullWidth
                    name="dob"
                    type="date"
                    margin="normal"
                    formatDate={(date) => moment(date).format('DD-MM-YYYY')}
                    placeholder="YYYY-MM-DD"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    className={classes.od__textField}
                    error={Boolean(touched.dob && errors.dob)}
                    helperText={touched.dob && errors.dob}
                    required
                  />
                </Box>

                <Box mt={2}>
                  <Typography>Highest Degree <span style={{ color: 'red' }}>*</span></Typography>
                  <TextField
                    fullWidth
                    name="degree"
                    margin="normal"
                    placeholder="MD, MBBS, MS, etc."
                    onBlur={handleBlur}
                    onChange={handleChange}
                    className={classes.od__textField}
                    error={Boolean(touched.degree && errors.degree)}
                    helperText={touched.degree && errors.degree}
                    required
                  />
                </Box>
                <Box mt={2}>
                  <Typography>Phone Number <span style={{ color: 'red' }}>*</span></Typography>
                  <TextField
                    fullWidth
                    name="phone_number"
                    margin="normal"
                    placeholder="+91"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    className={classes.od__textField}
                    error={Boolean(touched.phone_number && errors.phone_number)}
                    helperText={touched.phone_number && errors.phone_number}
                    required
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <Box mt={2}>
                  <Typography>Address <span style={{ color: 'red' }}>*</span></Typography>
                  <TextField
                    fullWidth
                    multiline
                    name="address"
                    margin="normal"
                    placeholder="Address"
                    onBlur={handleBlur}
                    className={classes.od__textField}
                    onChange={handleChange}
                    error={Boolean(touched.address && errors.address)}
                    helperText={touched.address && errors.address}
                    required
                  />
                </Box>
                <Box mt={2}>
                  <Typography>Year of Experience <span style={{ color: 'red' }}>*</span></Typography>
                  <TextField
                    fullWidth
                    name="experience"
                    margin="normal"
                    placeholder="0"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    className={classes.od__textField}
                    error={Boolean(touched.experience && errors.experience)}
                    helperText={touched.experience && errors.experience}
                    required
                  />
                </Box>
                <Box mt={2}>
                  <Typography>Email Address <span style={{ color: 'red' }}>*</span></Typography>
                  <TextField
                    fullWidth
                    name="email"
                    margin="normal"
                    placeholder="example@example.com"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    className={classes.od__textField}
                    error={Boolean(touched.email && errors.email)}
                    helperText={touched.email && errors.email}
                    required
                  />
                </Box>
                <Box>
                  <Typography>Doctor Medical Registration Number <span style={{ color: 'red' }}>*</span></Typography>
                  <TextField
                    fullWidth
                    name="registration_no"
                    margin="normal"
                    placeholder="DOCIND78956236"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    className={classes.od__textField}
                    error={Boolean(touched.registration_no && errors.registration_no)}
                    helperText={touched.registration_no && errors.registration_no}
                    required
                  />
                </Box>
                <Box mt={2}>
                  <Typography>Doctor Medical Registration Certificate</Typography>
                  <Box className={classes.od__uploadFile_border}>
                    <input
                      type="file"
                      ref={fileRef}
                      name="file"
                      onChange={handleMedia}
                      style={{ display: 'none' }}
                    />
                    {
                      !isFilePicked
                        ?
                        <Box
                          className={classes.od__uploadFile}
                          onClick={() => fileRef.current.click()}
                        >
                          <AddFileIcon />
                          <Typography>Upload File</Typography>
                        </Box>
                        :
                        <div>
                          <Box
                            className={classes.od__uploadFile}
                          >
                            <AttachmentIcon />
                            <Box>
                              <Typography>{selected.name}</Typography>
                            </Box>
                          </Box>
                          <IconButton
                            className={classes.od__uploadFile__remove}
                            onClick={onRemove}
                          >
                            <CloseIcon />
                          </IconButton>
                        </div>
                    }
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <Button
                  variant="contained"
                  fullWidth
                  className={classes.od__form__btn}
                  type="submit"
                >
                  Add Doctor
                </Button>
              </Grid>
            </Grid>
          </form>
        )}
      </Formik>

    </Box>

  )
}

export default AddNewDoctorForm;