import { Box, Typography, Avatar } from '@material-ui/core';
import getInitials from 'src/utils/getInitials';


// Stylesheet
import classes from 'src/styles/employee/employeeInfo.module.scss';

export default function VitalReading({vital}) {

  const showIndicator = (name) => {
    switch(name){
      case "weight":
        return "Kg"
      case "blood_pressure":
        return "mmHg"
      case "temprature":
        return <><sup>o</sup>C</>
      case "pulse":
        return "bpm"
      case "glucose":
        return "mg/dL"
      case "spo2":
        return "%"
      default: 
        return ""
    }
  }

  return (
    <Box 
      className={classes.ei__vital__reading__box}
      sx={{
        border: vital.status === "alert" 
          ?  "1px solid #d70015" 
          : vital.status === "moderate"
          ? "1px solid #0d809f"
          : "1px solid limegreen",
      }}    
    >
      <Box className={classes.inner__box}>
        <Avatar
          // src={emp.avatarUrl}
          sx={{
            mr: 2,
            background: '#2D809F',
            fontSize: 20,
            width: 50,
            height: 50
          }}
        >
          {getInitials(vital.name)}
        </Avatar>
        <Typography className={classes.indicator}>
          {
            vital.status === "alert"  && 
            <div className={classes.ring__container}>
              <span className={classes.ringring}></span>
              <span className={classes.circle}></span>
            </div>
          }
          
          <span
            style={{
              color: vital.status === "alert" 
                ?  "#d70015" 
                : vital.status === "moderate"
                ? "#0d809f"
                : "limegreen",
              textTransform: "capitalize"
            }}
          >
            {vital.status}
          </span>
        </Typography>
      </Box>
      <Box >
        <Typography className={classes.title}>
            {
              vital.name === "blood_pressure" 
              ? "Blood Pressure" 
              : vital.name === "oxygen_saturation"
              ? "Oxygen Saturation"
              :
              vital.name
            }
          </Typography>
      </Box>
      <Typography className={classes.reading}>
        {vital.reading2 ? <>{vital.reading1.toFixed(2)}/{vital.reading2.toFixed(2)}</> : vital.reading1.toFixed(2)} {showIndicator(vital.name)}
      </Typography>
    </Box>
  );
}
