import {
  ResponsiveContainer,
  Tooltip,
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid
} from 'recharts';

export default function EmployeeTestChart({ chartData }) {
  return (
    <ResponsiveContainer width="100%" height="100%">
      <LineChart width={700} height={400} data={chartData}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="weekday" />
        <YAxis dataKey="count" />
        <Tooltip />
        <Line
          type="monotone"
          dataKey="count"
          stroke="#0D809F"
          strokeWidth={3}
        />
      </LineChart>
    </ResponsiveContainer>
  );
}
