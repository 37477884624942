import { Navigate } from 'react-router-dom';


/** ========================
 * Main Layout
 =========================== */
 
 import MainLayout from 'src/layouts/MainLayout';
 import Login from './pages/Login';
 import ForgotPassword from './pages/ForgotPassword';
 import NotFound from './pages/NotFound';
// import Register from './pages/Register';

/** =========================
 * Dashboard Layout
 ============================ */

import DashboardLayout from 'src/layouts/DashboardLayout';
import EmployeeList from './pages/Employee';
import EmployeeReport from './pages/Reports';
import Dashboard from './pages/Dashboard';
import EmployeeInfo from './pages/EmployeeInfo';
import OurDoctor from './pages/OurDoctor';
import DoctorProfile from './pages/DoctorProfile';

/** ========================
 * Location Selector
 =========================== */
import LocationLayout from 'src/layouts/LocationLayout';
import ChooseLocation from 'src/pages/chooseLocation';

const routes = ( isAuthenticated ) => [
  {
    path: '/',
    element: isAuthenticated ? <Navigate to="/app/dashboard" /> : <MainLayout />,
    children: [
      { path: 'login', element: <Login /> },
      { path: 'reset-password', element: <ForgotPassword /> },
      { path: '404', element: <NotFound /> },
      { path: '/', element: <Navigate to="/app/dashboard" /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  },
  {
    path: 'location',
    element: isAuthenticated ? <LocationLayout /> : <Navigate to="/login" />,
    children: [
      { path: 'select', element: <ChooseLocation /> },
    ]
  },
  {
    path: 'app',
    element: isAuthenticated ? <DashboardLayout /> : <Navigate to="/login" />,
    children: [
      { path: 'location', element: <ChooseLocation /> },
      { path: 'employee', element: <EmployeeList /> },
      { path: 'employee/:slug', element: <EmployeeList /> },
      { path: 'dashboard', element: <Dashboard /> },
      { path: 'employee-report/:slug/:id', element: <EmployeeReport /> },
      { path: 'employee-info/:slug/:id', element: <EmployeeInfo /> },
      { path: 'our-doctor', element: <OurDoctor /> },
      { path: 'our-doctor/profile/:org/:id/:name', element: <DoctorProfile /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  } 
];

export default routes;
