import { useState, useEffect } from 'react';
import { Box, Typography, TextField, CircularProgress } from '@material-ui/core';

import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import LocalizationProvider from '@material-ui/lab/LocalizationProvider';
import DateRangePicker from '@material-ui/lab/DateRangePicker';
import AssessmentIcon from '@material-ui/icons/Assessment';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

// #React-redux
import { connect } from 'react-redux';
import { report } from 'src/redux/actions';

// Vitals View
import BloodPressureView from 'src/components/reports/BPView';
import TempratureView from 'src/components/reports/TemperatureView';
import WeightView from 'src/components/reports/WeightView';
import PulseView from 'src/components/reports/PulseView';
import GlucoseView from 'src/components/reports/GlucoseView';
import SPO2View from 'src/components/reports/SPO2View';

// Stylesheet
import classes from 'src/styles/reports/reports.module.scss';

/**================================================ */

/**
 * Parameters -> Unit
 * Blood Pressure -> mmHg (millimeters of mercury)
 * Temprature -> Celsius
 * Weight -> Kilogram
 * Pulse -> bpm (beats per minute)
 * Glucose -> mg/dL (milligrams per decilitre)
 * SpO2 -> % (Percentage)
 */

/**================================================ */

const ReportLogsView = ({ 
  fetchVitalsReports,
  bpReadings,
  bpGraph,
  tempReadings,
  tempGraph,
  glucoseReadings,
  glucoseGraph,
  pulseReadings,
  pulseGraph,
  weightReadings,
  weightGraph,
  spo2Readings,
  spo2Graph,
}) => {

  const { slug, id } = useParams();

  // states
  const [dateRange, setDateRange] = useState([
    moment().subtract(30, 'days'),
    new Date()
  ]);
  const [loading, setLoading] = useState(false);
  


  // Fetch Vitals with date-range
  useEffect(() => {
    const startDate = moment(dateRange[0]).format("DD-MM-YY");
    const endDate = moment(dateRange[1]).format("DD-MM-YY")
    if(moment(endDate, "DD-MM-YY", true).isValid() && moment(endDate, "DD-MM-YY", true).isValid()){
      setLoading(true)
      let query = `from_date=${startDate}&to_date=${endDate}&profile_id=${id}`;
      fetchVitalsReports(query)
        .then(res => {
          setLoading(false)
        })
        .catch(err => {
          setLoading(false)
          toast.error("Network Error: There is a problem while fetching")
        })
    } else {
      toast.error("Please select a valid date range.")
    }
  },[dateRange])

  // Handling Date Change
  const handleDateChange = (dateRange) => {
    setDateRange(dateRange);
  };

  return (
    <>
        <Box  className={classes.rt__root}>
          <Box className={classes.rt__header}>
            <Box className={classes.rt__inner__box}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DateRangePicker
                  value={dateRange}
                  inputFormat="dd/MM/yyyy"
                  onChange={(newRange) => handleDateChange(newRange)}
                  disableFuture
                  // disablePast
                  // minDate={moment("2021-09-20")}
                  renderInput={(startProps, endProps) => (
                    <>
                      <TextField variant="standard" {...startProps} />
                      <Box sx={{ mx: 2 }}>to</Box>
                      <TextField variant="standard" {...endProps} />
                    </>
                  )}
                />
              </LocalizationProvider>
            </Box>
          </Box>
          {
            loading 
            ?
            <Box className="is__empty__data">
              <CircularProgress color="primary" disableShrink />
            </Box>
            :
            <>
              {/* Check Empty */}
              {
                !Boolean(bpReadings) &&
                !Boolean(tempReadings) &&
                !Boolean(weightReadings) &&
                !Boolean(pulseReadings) &&
                !Boolean(glucoseReadings) &&
                !Boolean(spo2Readings) 
                ?
                <Box className="is__empty__data">
                  <AssessmentIcon
                    sx={{
                      width: 70,
                      height: 70,
                      color: "gray",
                      pb: 2
                    }}
                  />
                  <Typography
                    sx={{
                      fontWeight: 500,
                      color: "gray",
                      letterSpacing: "0.8px",
                      px: 4,
                      textAlign: "center"
                    }}
                  >
                    Employee doesn't have taken any tests yet.
                  </Typography>
                </Box>
                :
                ""
              }

              {/* Blood Pressure */}

              <Box>
                {
                  bpReadings && bpGraph &&
                  <BloodPressureView tableData={bpReadings} graphData={bpGraph} />
                }
              </Box>

              {/* Temprature */}

              <Box py={(2.5)}>
                {
                  tempReadings && tempGraph && 
                  <TempratureView tableData={tempReadings} graphData={tempGraph}  />
                }
              </Box>

              {/* Weight */}

              <Box py={2.5}>
                {
                  weightReadings && weightGraph &&
                  <WeightView tableData={weightReadings} graphData={weightGraph} />
                }
              </Box>

              {/* Pulse */}

              <Box py={2.5}>
                {
                  pulseReadings && pulseGraph &&
                  <PulseView tableData={pulseReadings} graphData={pulseGraph} />
                }
              </Box>

              {/* Glucose */}

              <Box py={2.5}>
                {
                  glucoseReadings && glucoseGraph &&
                  <GlucoseView tableData={glucoseReadings} graphData={glucoseGraph} />
                }
              </Box>

              {/* SPO2 */}

              <Box py={2.5}>
                {
                  spo2Readings && spo2Graph &&
                  <SPO2View tableData={spo2Readings} graphData={spo2Graph} />
                }
              </Box>
            </>
          }
        </Box>
      {/* </Box> */}
    </>
  );
};

const mapStateToProps = (state) => ({
  bpReadings: state.report.bpReadings,
  bpGraph: state.report.bpGraph,
  tempReadings: state.report.tempReadings,
  tempGraph: state.report.tempGraph,
  glucoseReadings: state.report.glucoseReadings,
  glucoseGraph: state.report.glucoseGraph,
  pulseReadings: state.report.pulseReadings,
  pulseGraph: state.report.pulseGraph,
  weightReadings: state.report.weightReadings,
  weightGraph: state.report.weightGraph,
  spo2Readings: state.report.spo2Readings,
  spo2Graph: state.report.spo2Graph,
});

const mapDispatchToProps = (dispatch) => ({
  fetchVitalsReports: (queryParams) => dispatch(report.fetchVitalsReports(queryParams)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ReportLogsView);
