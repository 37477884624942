import { useState, useEffect, } from 'react';
import { Helmet } from 'react-helmet';
import {
  Box,
  Container,
  Typography,
  Tabs,
  Tab,
} from '@material-ui/core';

import { useParams, useNavigate, createSearchParams } from 'react-router-dom';

/* ========= Tools ========== */
import TabPanel from "src/utils/tabPanel";
import a11yProps from "src/utils/a11yProps";
import getParameterByName from "src/utils/getSearchParams";

// #React-redux
import { connect } from 'react-redux';
import { report } from 'src/redux/actions';

// Views
import EmployeeProfileDetailsView from "src/components/employeeInfo/EmployeeProfileDetails";
import EmployeeVitalsReportView from "src/components/employeeInfo/EmployeeVitalsReportView";
import ReportLogsView from "src/components/employeeInfo/ReportLogs";
import EmployeeReportView from "src/components/employeeInfo/EmployeeReportView";

// Stylesheet
import classes from "src/styles/employee/employeeInfo.module.scss";

const EmployeeInfo = ({
  getEmployee,
  employeeProfile,
  lastRecentTests,
  lastTests,
  fetchEmployeeReports,
  locationChange
}) => {

  const { slug, id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    getEmployee(slug)
    lastRecentTests(id)
  }, [slug])


  /**
   * 
   * FETCHING REPORT FILES 
   * 
   */

  const [pageNum, setPageNum] = useState(1);
  const [loading, setLoading] = useState(false);

  // Fetching report here to avoid multiple request 
  useEffect(() => {
    setLoading(true)
    fetchEmployeeReports(`profile_id=${id}&page=${pageNum}`)
      .then(res => setLoading(false))
  }, [id, pageNum])


  // Handling tabs 
  const [value, setValue] = useState(0);
  const query = getParameterByName("tab");

  const handleChange = (event, newValue) => {
    setValue(newValue);
    navigate({
      pathname: window.location.pathname,
      search: `?${createSearchParams({
        tab: newValue
      })}`
    })
  };

  useEffect(() => {
    if (query === null) {
      navigate({
        pathname: window.location.pathname,
        search: `?${createSearchParams({
          tab: value
        })}`
      })
    } else {

      /**
       *  
       * We have tab number for now 3 in [0, 1, 2] format
       * Now Check if tab number is greater than tab length
       * 
       */
      if (Number(query) >= 3) {

        // If tab number is greater than tab length 
        // push the tab to the 0.
        navigate({
          pathname: window.location.pathname,
          search: `?${createSearchParams({
            tab: 0
          })}`
        })
        setValue(0)

      } else {
        setValue(Number(query))
      }
    }

  }, [value, query])


  /**
   * 
   * UNMOUNTING REDUX STATE
   * 
   * We are doing this because we don't want to make repeated requests after changing tabs.
   * Because we're using infinite scroll in report tab section.
   * 
   * (We can use component state to avoid this unmounting. But if use component state then request size has been increased.)
   * 
   * - :)
   * 
   */

  useEffect(() => {
    return () => {
      locationChange()
    }
  }, [])


  return (
    <>
      <Helmet>
        <title>Employee Info | TraceLyfe</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.secondary',
          minHeight: '100%',
          py: 3
        }}
      >
        <Container maxWidth={false} className={classes.ei__root}>
          <Box className={classes.ei__header} >
            <Box>
              <Typography className={classes.ei__breadcrumbs}>
                <span style={{ cursor: "pointer" }} onClick={() => navigate("/app/employee")}>Employee</span>
                <span style={{ padding: "0px 5px" }}>|</span>
                <span style={{ color: "#0d809f" }}>Employee Information</span>
              </Typography>
              <Typography className={classes.ei__title}>
                Employee Information
              </Typography>
            </Box>
          </Box>
          <Box>
            {
              employeeProfile &&
              <EmployeeProfileDetailsView profile={employeeProfile} />
            }
          </Box>
          <Box className={classes.tab__section__root}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="report tabs"
              variant="fullWidth"
              className={classes.tab__root}
            >
              <Tab
                label="Summary"
                {...a11yProps(0)}
                classes={{
                  root: classes.tabs,
                  selected: classes.tab__selected
                }}
              />
              <Tab
                label="Report"
                {...a11yProps(1)}
                classes={{
                  root: classes.tabs,
                  selected: classes.tab__selected
                }}
              />
              <Tab
                label="Logs"
                {...a11yProps(2)}
                classes={{
                  root: classes.tabs,
                  selected: classes.tab__selected
                }}
              />


              {/* Just to show full width border  */}

              <span className={classes.tab__extraBorder}>{" "}</span>
            </Tabs>
            <TabPanel value={value} index={0}>

              <Box py={8, 4} className={classes.ei__bottom__container}>
                <EmployeeVitalsReportView lastRecentTests={lastTests} />
              </Box>
            </TabPanel>
            <TabPanel value={value} index={1}>
              <Box pt={2}>
                <EmployeeReportView pageNum={pageNum} setPageNum={setPageNum} loading={loading} />
              </Box>
            </TabPanel>
            <TabPanel value={value} index={2}>
              <ReportLogsView />
            </TabPanel>
          </Box>

        </Container>
      </Box>
    </>
  )
}

const mapStateToProps = (state) => ({
  employeeProfile: state.report.employeeProfile,
  lastTests: state.report.lastTests,
});

const mapDispatchToProps = (dispatch) => ({
  getEmployee: (emp_id) => dispatch(report.getEmployee(emp_id)),
  lastRecentTests: (emp_id) => dispatch(report.lastRecentTests(emp_id)),
  fetchEmployeeReports: (query) => dispatch(report.fetchEmployeeReports(query)),
  locationChange: () => dispatch(report.locationChange())
});


export default connect(mapStateToProps, mapDispatchToProps)(EmployeeInfo);