import { useState } from 'react';
import { 
  Box, 
  Typography, 
  Chip, 
  Button,
  Tabs,
  Tab 
} from '@material-ui/core';

/* ======= Tools ========= */
import TabPanel from "src/utils/tabPanel";
import a11yProps from "src/utils/a11yProps";

/* ======= Views ======= */
import ConnectDialog from './connectDialog';
import { DoctorProfile } from "./orgDoctorRightScreens";
import RequestMessage from './reqMessage';

/* ======= Stylesheet ======= */
import classes from 'src/styles/ourDoctor/ourDoctor.module.scss';

/**
 * 
 * Doctor Profile Screens only for 
 * who are not accepted the organization invitation
 * 
 */

export default function PendingDoctorRightView({profile}) {

  const [open, setOpen] = useState(false);
  const [value, setValue ] = useState(0);

  const handleTabChange = (event, newValue) => {
    setValue(newValue)
  }

  return (
    <Box className={classes.od_prof_right__root}>
      <Tabs
        value={value} 
        onChange={handleTabChange} 
        aria-label="report tabs" 
        variant="fullWidth"
        className={classes.tab__root}
      >
        <Tab 
          label="Message Sent" 
          {...a11yProps(0)} 
          classes={{
            root: classes.tabs,
            selected: classes.tab__selected
          }} 
        />
        <Tab 
          label="Doctor Profile" 
          {...a11yProps(1)} 
          classes={{
            root: classes.tabs,
            selected: classes.tab__selected
          }} 
        />
        {/* <Tab 
          label={`Appointments & Feedbacks`} 
          {...a11yProps(2)} 
          classes={{
            root: classes.tabs,
            selected: classes.tab__selected
          }} 
        /> */}
      </Tabs>
      <TabPanel value={value} index={0}>
        <RequestMessage classes={classes} message={profile && profile.invite} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <DoctorProfile profile={profile} classes={classes} pending={true} />
      </TabPanel>
      
      {
        open && 
        <ConnectDialog 
          open
          setOpen={setOpen}
          profile={profile}
          outer={false}
        />
      }
    </Box>
  );
}
