import {
  Card,
  CardContent,
  Grid,
  Typography
} from '@material-ui/core';

// Stylesheet
import classes from 'src/styles/dashboard/dashboard.module.scss';

const PeopleAtModerateRisk = ({count, ...props}) => (
  <Card 
    sx={{
      boxShadow: '0px 2px 12px rgb(21 21 22 / 4%) !important',
      border: '1px solid #E6EAEE',
      borderRadius: "13px"
    }}
    {...props}
  >
    <CardContent>
      <Grid container spacing={3} className={classes.pmr__container}>
        <Grid item>
          <Typography className={classes.pmr__title} gutterBottom variant="h6">
            People At Moderate Risk
          </Typography>
          <Typography className={classes.pmr__stat} variant="h3">
            {count}
          </Typography>
        </Grid>
      </Grid>
    </CardContent>
  </Card>
);

export default PeopleAtModerateRisk;
