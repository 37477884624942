import { useState } from "react";
import { Link as RouterLink } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Container,
  TextField,
  Typography,
  CircularProgress,
  Link
} from '@material-ui/core';
import { toast } from "react-toastify";


// @react-redux
import { connect } from "react-redux";
import { auth } from "src/redux/actions";

// Brand Logo
import Logo from "src/components/Logo";

const Login = ({ loginUser }) => {
  const [loading, setLoading] = useState(false)

  const handleLogin = (values) => {
    setLoading(true)
    loginUser(values)
      .then(res => {
        toast.success("You have successfully logged in to TraceLyfe.")
        setLoading(false)
        window.location.reload()
      })
      .catch(err => {
        toast.error('Something went wrong.')
        setLoading(false)
      })
  }


  return (
    <>
      <Helmet>
        <title>Login | TraceLyfe</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.paper',
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          justifyContent: 'center'
        }}
      >
        <Container maxWidth="sm">
          <Formik
            initialValues={{}}
            validationSchema={Yup.object().shape({
              phone_number: Yup.number().required(),
              password: Yup.string().max(255).required('Password is required')
            })}
            onSubmit={handleLogin}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values
            }) => (
              <form onSubmit={handleSubmit}>
                <Box sx={{ display: 'flex', justifyContent: 'center', py: 5 }}>
                  <RouterLink to="/">
                    <Logo width="200px" />
                  </RouterLink>
                </Box>
                <Box sx={{ mb: 3 }}>
                  <Typography
                    color="textPrimary"
                    variant="h2"
                  >
                    Sign in
                  </Typography>
                  <Typography
                    color="textSecondary"
                    gutterBottom
                    variant="body2"
                  >
                    Sign in on the internal platform
                  </Typography>
                </Box>
                <TextField
                  error={Boolean(touched.phone_number && errors.phone_number)}
                  fullWidth
                  helperText={touched.phone_number && errors.phone_number}
                  label="Enter Phone Number"
                  margin="normal"
                  name="phone_number"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="number"
                  value={values.email}
                  variant="outlined"
                />
                <TextField
                  error={Boolean(touched.password && errors.password)}
                  fullWidth
                  helperText={touched.password && errors.password}
                  label="Password"
                  margin="normal"
                  name="password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="password"
                  value={values.password}
                  variant="outlined"
                />
                <Typography
                  color="textSecondary"
                  variant="body1"
                  sx={{
                    textAlign: "right"
                  }}
                >
                  <Link component={RouterLink} to="/reset-password" variant="h5" underline="hover">
                    Forgot Password?
                  </Link>
                </Typography>
                <Box sx={{ py: 2 }}>
                  <Button
                    color="primary"
                    disabled={loading}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                    endIcon={loading ? <CircularProgress color="inherit" size={20} /> : ''}
                  >
                    Sign in now
                  </Button>
                </Box>

              </form>
            )}
          </Formik>
        </Container>
      </Box>
    </>
  );
};


const mapDispatchToProps = dispatch => ({
  loginUser: (data) => dispatch(auth.loginUser(data))
})


export default connect(null, mapDispatchToProps)(Login);
