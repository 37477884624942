import 'react-perfect-scrollbar/dist/css/styles.css';
import { useRoutes } from 'react-router-dom';
import { ThemeProvider, StyledEngineProvider } from '@material-ui/core';
import React from "react";
// @react-router 
import { useSelector } from "react-redux";

import ShowMessage from "src/utils/showMessage"
import GlobalStyles from './components/GlobalStyles';
import theme from './theme';
import routes from './routes';

import axiosInterceptorHandler from "src/interceptor/axiosInstance"

// import "react-toastify/dist/ReactToastify.css";


const App = () => {
  const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
  const content = useRoutes(routes(isAuthenticated));


  // Handling axios interceptor to refresh jwt auth token =====
  axiosInterceptorHandler();
  

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <GlobalStyles /> 
        {content}
        <ShowMessage />
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default App;
