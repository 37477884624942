import {
  Box,
  Typography,
  Button,
} from "@material-ui/core";
import EjectIcon from '@material-ui/icons/Eject';


export default function RequestMessage({classes, message}){
  return(
    <>
      <Box className={classes.inner__box} p={2} minHeight="60vh">
        <Typography className={classes.description}>
          {message}
        </Typography>
        <Box mt={3} display="flex" justifyContent="flex-end">
          <Typography className={classes.timeAgo}>2 hours ago</Typography>
        </Box>
      </Box>
      <Box p={2}>
        <Box className={classes.org__footer__box} mt={3}>
          <Button 
            variant="contained" 
            startIcon={<EjectIcon />}
            onClick={() => {
              alert('Coming Soon.')
            }}
          >
            Withdraw Request
          </Button>
        </Box>
      </Box>
    </>
  )
}