export const gender = [
  {
    key: "male",
    name: "Male"
  }, 
  {
    key: "female",
    name: "Female"
  },
  {
    key: "other",
    name: "Other"
  }
]