import { useState, useRef, useCallback, useEffect } from "react";
import {
  Box,
  Typography,
  Chip,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from "@material-ui/core";
import ChatIcon from '@material-ui/icons/Chat';
import { useNavigate } from 'react-router-dom';

import { useDispatch } from 'react-redux';
import { doctor } from 'src/redux/actions';

// Composer 
import EmailComposer from "./emailComposer";


export const DoctorProfile = ({profile, classes, pending}) => {

  const [open, setOpen] = useState(false);


  return(
    <>
      <Box 
        className={classes.inner__box} 
        p={2}
        minHeight={
          pending ? "75vh" : "100%"
        }
      >
        <Typography className={classes.title}>About Doctor</Typography>
        <Typography className={classes.description}>
          Lorem Ipsum is simply dummy text of the printing and typesetting
          industry. Lorem Ipsum has been the industry's standard dummy text ever
          since the 1500s, when an unknown printer took a galley of type and
          scrambled it to make a type specimen book. It has survived not only
          five centuries, but also the leap into electronic typesetting,
          remaining essentially unchanged. It was popularised in the 1960s with
          the release of Letraset sheets containing Lorem Ipsum passages, and
          more recently with desktop publishing software like Aldus PageMaker
          including versions of Lorem Ipsum.
        </Typography>
        <Box mt={3}>
          <Typography className={classes.title}>Speciality</Typography>
          <Box mt={2}>
            {
              profile.qualification.length !== 0 
              ?
              <>
                {
                  profile.speciality.map((item, index) => (
                    <Chip key={index} label={item.name} className={classes.chip} />
                  ))
                }
              </>
              :
              <>
                <Typography>Doctor has no speciality.</Typography>
              </>
            }
          </Box>
        </Box>
        <Box mt={3}>
          <Typography className={classes.title}>Qualification</Typography>
          <Box mt={2}>
            {
              profile.qualification.length !== 0 
              ?
              <>
                {
                  profile.qualification.map((item, index) => (
                    <Chip key={index} label={item.degree} className={classes.chip} />
                  ))
                }
              </>
              :
              <>
                <Typography>Doctor has no qualification.</Typography>
              </>
            }
          </Box>
        </Box>
      </Box>
      {
        pending
        ?
        ""
        :
        <Box p={2}>
          <Box className={classes.org__footer__box} mt={3}>
            <Button 
              variant="contained" 
              startIcon={<ChatIcon />}
              onClick={() => {
                setOpen(true)
              }}
            >
              Message Doctor
            </Button>
          </Box>
        </Box>
      }
      {
        open && 
        <EmailComposer 
          open={open}
          setOpen={setOpen}
          profile={profile}
        />
      }
    </>
  )
}


export const AppointmentAndFeedbacks = () => {
  return(
    <Box p={2}>
      <Typography>Appointment And Feedbacks</Typography>
    </Box>
  )
}


// Employee Assigned Screen 

export const EmployeeAssigned = ({empData, classes}) => {

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleRedirect = (emp) => {
    console.log("Employee", emp)
    navigate(`/app/employee-info/${emp.employee_code}/${emp.id}`)
  }

  // Infinite Scroll observer 

  const [pageNum, setPageNum ] = useState(1);

  const observer = useRef();
  const lastEl = useCallback(node => {
    if(observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver(entries => {
      if(entries[0].isIntersecting && Boolean(empData.next)){
        console.log("Hello World")
        setPageNum(pageNum => pageNum + 1)
      }
    })

    if(node) observer.current.observe(node);

  }, [empData])

  useEffect(() => {
    if(pageNum){
      dispatch(doctor.updatedPageNum(pageNum))
    }
  }, [pageNum])

  return(
    <Box p={2} className={classes.od__prof__emp}>
      <Box className={classes.od__prof__emp__grid}>
        {/* <Box className={classes.od__prof__emp__box}>
          <Typography className={classes.od__prof__emp__box__title}>Departments</Typography>
          <Typography className={classes.od__prof__emp__box__num}>7</Typography>
        </Box> */}
        <Box className={classes.od__prof__emp__box}>
          <Typography className={classes.od__prof__emp__box__title}>Employees</Typography>
          <Typography className={classes.od__prof__emp__box__num}>
            { empData && empData.count }
          </Typography>
        </Box>
      </Box>
      <Box>
        <Box mt={2} className={classes.od__prof__emp__table}>
          {/* <Typography className={classes.od__prof__emp__table__title} gutterBottom>
            Information Technology
          </Typography> */}
          <TableContainer className={classes.od__prof__emp__table__root}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell align="left">Emp. Code</TableCell>
                  <TableCell align="center">Name</TableCell>
                  <TableCell align="center">Email</TableCell>
                  <TableCell align="center">Location</TableCell>
                  <TableCell align="right">Gender</TableCell>
                  {/* <TableCell align="right">Reg. Date</TableCell> */}
                </TableRow>
              </TableHead>
              <TableBody>
                {
                  empData && empData.results.map((item, index) => {
                    if(empData.results.length === index + 1){
                      return(
                        <TableRow
                          hover
                          key={index}
                          sx={{cursor: 'pointer'}}
                          onClick={() => handleRedirect(item.profile)}
                          ref={lastEl}
                        >
                          <TableCell align="left">{item.profile.employee_code}</TableCell>
                          <TableCell align="center">{item.profile.name}</TableCell>
                          <TableCell align="center" sx={{textTransform: 'lowercase !important'}}>{item.profile.email}</TableCell>
                          <TableCell align="center">{item.profile.address}</TableCell>
                          <TableCell align="right">{item.profile.gender}</TableCell>
                          {/* <TableCell>{item.profile.employee_code}</TableCell> */}
                        </TableRow>
                      )
                    } else {
                      return(
                        <TableRow
                          hover
                          key={index}
                          sx={{cursor: 'pointer'}}
                          onClick={() => handleRedirect(item.profile)}
                        >
                          <TableCell align="left">{item.profile.employee_code}</TableCell>
                          <TableCell align="center">{item.profile.name}</TableCell>
                          <TableCell align="center" sx={{textTransform: 'lowercase !important'}}>{item.profile.email}</TableCell>
                          <TableCell align="center">{item.profile.address}</TableCell>
                          <TableCell align="right">{item.profile.gender}</TableCell>
                          {/* <TableCell>{item.profile.employee_code}</TableCell> */}
                        </TableRow>
                      )
                    }
                  })
                }
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
    </Box>
  )
}
