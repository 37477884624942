import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './redux/store';
import { auth } from "src/redux/actions";
import App from './App';

import "src/styles/main.scss";

/** ==================================
 * Checking if user already logged In or not. 
 * If user already logged In -> Tell store we have access token 
 * to fetch resources.
 ===================================== **/

const ISSERVER = typeof window === 'undefined';

if(!ISSERVER){
  let access_token = localStorage.getItem('access_token');
  let refresh_token = localStorage.getItem('refresh_token');

  if(access_token){
    store.dispatch(auth.loginUserSuccess({access_token, refresh_token}));
  }

}


ReactDOM.render(
  (
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  ),
  document.getElementById('root')
);
