import { useState } from "react";
import {
  Box,
  Typography,
  Grid,
  Avatar,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Button,
  Chip
} from "@material-ui/core";

import { useNavigate, createSearchParams } from "react-router-dom";

/* ========= React Redux ========== */
import { useDispatch, useSelector } from "react-redux";
import { doctor } from "src/redux/actions";

import getInitials from "src/utils/getInitials";

import ConnectDialog from 'src/components/doctorProfile/connectDialog';

/* ========= Stylesheet ========= */ 
import classes from "src/styles/ourDoctor/ourDoctor.module.scss"

const DoctorListView = ({ totalDoctor, recommended, pending }) => {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const doctorProfile = useSelector(state => state.doctor.doctorProfile);
  const [open, setOpen] = useState(false);

  const openDialog = (item) => {
    setOpen(true)
    const query = `doctor_id=${item.id}&types=recomdoc`
    dispatch(doctor.getDoctorProfile(query))
  }

  return(
    <Box p={2}>
      <Grid container spacing={3}>
       {
         totalDoctor.map((item, index) => (
          <Grid item xs={12} sm={12} md={4} key={index} >
            <Box className={classes.od__doc__box}>
              <Box display="flex" alignItems="center">
                <Avatar
                  src={item.profile_pic}
                  className={classes.od_doc_avatar}
                >
                  {getInitials(item.name)}
                </Avatar>
                <Typography className={classes.title}>
                  {item.name}
                  <span>
                    {
                      item.registration_no
                      ?
                      <>
                        #{item.registration_no}
                      </>
                      :
                      <>
                        Not Registered
                      </>
                    }
                  </span>
                </Typography>
              </Box>
              <Box className={classes.od_doc_details} mt={1}>
                
                <List>
                  <ListItem sx={{p: 0}}>
                    {/* <ListItemIcon>
                      <Typography className={classes.list__primary}>Speciality:</Typography>
                    </ListItemIcon>
                    <ListItemText className={classes.list__secondary} primary="Medical " /> */}
                    
                    {
                      recommended || pending
                      ?
                      <>
                        <ListItemIcon>
                          <Typography className={classes.list__primary}>Qualification:</Typography>
                        </ListItemIcon>
                        <ListItemText 
                          className={classes.list__secondary} 
                          primary={
                            Boolean(item.degree)
                            ?
                            item.degree
                            :
                            "Doctor has no speciality."
                          } 
                        /> 
                      </>
                      :
                      <>
                       {
                          item.speciality.length !== 0 
                          ?
                          <>
                            {
                              item.speciality.map((qual, index) => (
                                <Chip key={index} label={qual.name} className={classes.chip} />
                              ))
                            }
                          </>
                          :
                          <>
                            <Typography className={classes.have__nothing}>Doctor has no speciality.</Typography>
                          </>
                        }
                      </>
                    }
                  </ListItem>
                  {
                    recommended || pending
                    ?
                    <ListItem sx={{p: 0}}>
                      <ListItemIcon>
                        <Typography className={classes.list__primary}>Experience:</Typography>
                      </ListItemIcon>
                      <ListItemText className={classes.list__secondary} primary={`${item.experience} year`} />
                    </ListItem>
                    :
                    <ListItem sx={{p: 0}}>
                      <ListItemIcon>
                        <Typography className={classes.list__primary}>Employees:</Typography>
                      </ListItemIcon>
                      <ListItemText className={classes.list__secondary} primary={item.total_employee} />
                    </ListItem>
                  }
                  
                  <ListItem sx={{p: 0}}>
                    {
                      recommended || pending
                      ?
                      ""
                      :
                      <>
                        <ListItemIcon>
                          <Typography className={classes.list__primary}>Qualification:</Typography>
                        </ListItemIcon>
                        {/* <ListItemText className={classes.list__secondary} primary="Biology, Cardiology " /> */}
                        <ListItemText  className={classes.list__secondary}>
                          {
                            item.qualification.length !== 0 
                            ?
                            <span style={{fontSize: 14}}>
                              { item.qualification.map(qual => qual.degree).join(", ") }
                            </span>
                            :
                            <>
                              <Typography className={classes.have__nothing}>Doctor has no qualification.</Typography>
                            </>
                          }
                        </ListItemText>
                      </>
                    }
                  </ListItem>
                </List>
              </Box>
              <Box className={classes.od__btn__box}>
                <Button
                  onClick={() => {
                    navigate({
                      pathname: `/app/our-doctor/profile/${recommended ? `other` : pending ? `pending` :`has`}/${item.id}/${item.name}`,
                      search: `?${createSearchParams({
                          org: recommended ? false : true,
                          connect: recommended ? true : false
                      })}`
                    })
                  }}
                >
                  View Profile
                </Button>
                {
                  pending &&
                   <Button
                      variant="contained"
                      sx={{
                        border: "0px !important",
                        marginLeft: "10px"
                      }}
                      // onClick={() => openDialog(item)}
                    >
                      Withdraw
                    </Button>
                }
                {
                  recommended &&
                   <Button
                      variant="contained"
                      sx={{
                        border: "0px !important",
                        marginLeft: "10px"
                      }}
                      onClick={() => openDialog(item)}
                    >
                      Connect with Doctor
                    </Button>
                }
              </Box>
              
            </Box>
          </Grid>
         ))
       }
       {
          open && doctorProfile &&
          <ConnectDialog 
            open={open}
            setOpen={setOpen}
            profile={doctorProfile}
            outer={true}
          />
        }
      </Grid>
    </Box>
  )
}

export default DoctorListView;