import {
  Typography
} from "@material-ui/core";

// Stylesheet
import classes from "src/styles/location/location.module.scss";

function ChooseLocation() {
  return (
    <div className={classes.main__container} style={{background: 'url(/static/images/location/map.png)'}}>
      <div className={classes.inner__container}>
        <Typography className={classes.title}>Select Location</Typography>
      </div>
    </div>
  )
}

export default ChooseLocation
