import axios from "axios";
import {
  GET_STATS,
  GET_PEOPLE_ON_HIGH_RISK,
  GET_NOF_TEST_GRAPH,
  GET_LAST_TEN_TEST,
} from "../constants/dashboard";

const DASHBOARD_BASE_URL = `${process.env.REACT_APP_API_URL}/employee/api`;
let headers = { 'Content-Type': 'application/json' };

export const fetchAllStats = () => async (dispatch, getState) => {
  try {
    const token = getState().auth.access_token;
    if (token) {
      headers['Authorization'] = `Bearer ${token}`;
      const response = await axios.get(`${DASHBOARD_BASE_URL}/organisation_stat/`, {
        headers
      });
      if (response.status === 200) {
        dispatch({ type: GET_STATS, data: response.data.data });
      }
    }
  } catch (error) {
    throw error;
  }
};

export const fetchAllOnHighRisk = () => async (dispatch, getState) => {
  try {
    const token = getState().auth.access_token;
    if (token) {
      headers['Authorization'] = `Bearer ${token}`;
      const response = await axios.get(`${DASHBOARD_BASE_URL}/all_high_risk_patient/`, {
        headers
      });
      if (response.status === 200) {
        dispatch({ type: GET_PEOPLE_ON_HIGH_RISK, data: response.data.high_risk_patient });
      }
    }
  } catch (error) {
    throw error;
  }
};

export const fetchNOFTestGraph = () => async (dispatch, getState) => {
  try {
    const token = getState().auth.access_token;
    if (token) {
      headers['Authorization'] = `Bearer ${token}`;
      const response = await axios.get(`${DASHBOARD_BASE_URL}/get-week-test-count/`, {
        headers
      });
      if (response.status === 200) {
        dispatch({ type: GET_NOF_TEST_GRAPH, data: response.data.data });
      }
    }
  } catch (error) {
    throw error;
  }
};

export const fetchLastTenTest = () => async (dispatch, getState) => {
  try {
    const token = getState().auth.access_token;
    if (token) {
      headers['Authorization'] = `Bearer ${token}`;
      const response = await axios.get(`${DASHBOARD_BASE_URL}/latest-ten-tested-patient/`, {
        headers
      });
      if (response.status === 200) {
        dispatch({ type: GET_LAST_TEN_TEST, data: response.data.data });
      }
    }
  } catch (error) {
    throw error;
  }
};