import {
  Grid,
  Box,
  Typography,
} from "@material-ui/core";
import VitalReading from "./VitalReading";

const EmployeeRecentTest = ({ lastRecentTests }) => {
  return(
    <Grid container spacing={2}>
      {
        lastRecentTests && lastRecentTests.length !== 0
        ?
        <>
          {
            lastRecentTests.map((item, index) => (
              <Grid item xs={12} sm={12} md={6} key={index}>
                <VitalReading vital={item} showIndicator={true}  />
              </Grid>
            ))
          }

        </>
        :
        <Box
          sx={{
            width: "100%",
            minHeight: 300,
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <Typography>Employees don't have taken any tests yet.</Typography>
        </Box>
      }
        
      {/* <Grid item xs={12} sm={12} md={6}>
        <VitalReading vital="Glucose" showIndicator={false}  />
      </Grid> */}
    </Grid>
  )
}

export default EmployeeRecentTest;