import axios from "axios";
import {
  GET_EMPLOYEE
} from "../constants/employee";

const EMPLOYEE_BASE_URL = `${process.env.REACT_APP_API_URL}/employee/api`;
let headers = { 'Content-Type': 'application/json' };

export const getEmployee = (data) => async (dispatch, getState) => {
  try {
    const token = getState().auth.access_token;
    if (token) {
      headers['Authorization'] = `Bearer ${token}`;
      const response = await axios.get(`${EMPLOYEE_BASE_URL}/get-employee/?${data}`, {
        headers
      });
      if (response.status === 200) {
        dispatch({ type: GET_EMPLOYEE, data: response.data.data });
      }
    }
  } catch (error) {
    throw error;
  }
};

export const addNewEmployee = (data) => async (dispatch, getState) => {
  try {
    const token = getState().auth.access_token;
    if (token) {
      headers['Authorization'] = `Bearer ${token}`;
      const response = await axios.post(
        `${EMPLOYEE_BASE_URL}/create-employee/`, 
        data,
        { headers }
        );
      if (response.status === 200 || response.status === 201) {
        dispatch(getEmployee('page=1'))
      }
    }
  } catch (error) {
    throw error.response;
  }
};


