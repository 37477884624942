import {
  Dialog,
  Box,
  Typography,
  IconButton,
  Grid,
  Hidden,
  TextField,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Avatar,
  Checkbox,
  FormHelperText,
  Button,
  Link,
  Chip
} from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import { Link as RouterLink, useNavigate } from 'react-router-dom';

/* ========= React Redux ========== */
import { useDispatch } from "react-redux";
import { doctor } from "src/redux/actions";

/* ========= Related to the form ========= */ 
import * as Yup from "yup";
import { Formik } from "formik";
import { toast } from "react-toastify";

import getInitials from "src/utils/getInitials";

/* ======= Stylesheet ======= */
import classes from "src/styles/ourDoctor/ourDoctor.module.scss"

const EmailComposer = ({
  open,
  setOpen,
  profile,
  outer  // Kind of a boolean that represent connect request coming from profile page or not.
}) => {

  const dispatch = useDispatch();

  const handleInvitation = (values) => {
    if(profile && values){
      values['doctor'] = profile.id;
      dispatch(doctor.sendNewMessage(values, outer))
        .then(res => {
          toast.success("Your message has been sent successfully.")
          setOpen(false)
        })
        .catch(err => {
          toast.error("Network Error: Sending Invitation.")
        })

    }
  }

  return(
    <Dialog
      open={open}
      onClose={(event, reason) => {
        if (reason !== 'backdropClick') {
          setOpen(false)
        }
      }}
      fullWidth
      maxWidth="md"
      disableEscapeKeyDown
    >
      <Grid container>
        <Grid item xs={12} sm={12} md={4} className={classes.dia__left}>
          <Box className={classes.dia__left__inner__box}>
            <Box display="flex" justifyContent="space-between" alignItems="center" >
              <Typography className={classes.title}>About Doctor</Typography>
              <Hidden smUp>
                <IconButton onClick={() => setOpen(false)}>
                  <CloseIcon />
                </IconButton>
              </Hidden>
            </Box>

            {/* Left Side */}
            <Box>
              <List>
                <ListItem>
                  <ListItemAvatar>
                    <Avatar
                      src={profile.profile_pic}
                      className={classes.dia_doc_avatar}
                    >
                      {getInitials(profile.name)}
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText 
                    primary={
                      <span
                        style={{
                          textTransform: 'capitalize'
                        }}
                      >
                        {profile.name}
                      </span>
                    } 
                    secondary="Tracelyfe Verified" 
                  />
                </ListItem>
                <Hidden smDown>
                  <ListItem>
                    <ListItemText 
                      primary="Doctor Registration No" 
                      secondary={
                        <span
                          style={{textTransform: "uppercase"}}
                        >
                          {profile.registration_no}
                        </span>
                      } 
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText 
                      primary="Qualification" 
                      secondary={
                        <Box mt={1}>
                          {
                            profile.qualification.length !== 0 
                            ?
                            <>
                              {
                                profile.qualification.map((item, index) => (
                                  <Chip key={index} label={item.degree} className={classes.chip} />
                                ))
                              }
                            </>
                            :
                            <>
                              <Typography>Doctor has no qualification.</Typography>
                            </>
                          }
                        </Box>
                      } 
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText 
                      primary="Speciality" 
                      secondary={
                      <Box mt={1}>
                        {
                          profile.qualification.length !== 0 
                          ?
                          <>
                            {
                              profile.speciality.map((item, index) => (
                                <Chip key={index} label={item.name} className={classes.chip} />
                              ))
                            }
                          </>
                          :
                          <>
                            <Typography>Doctor has no speciality.</Typography>
                          </>
                        }
                      </Box>
                      } 
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText 
                      primary="Experience" 
                      secondary={
                        <span>
                          {profile.experience} years
                        </span>
                      } 
                    />
                  </ListItem>
                </Hidden>
              </List>
            </Box>
          </Box>
        </Grid>

        {/* Right Side */}

        <Grid item xs={12} sm={12} md={8} className={classes.dia__right}>
          <Box className={classes.dia__right__inner__box}>
            <Box display="flex" justifyContent="space-between" alignItems="center" >
              <Typography className={classes.title}>New Message</Typography>
              <Hidden smDown>
                <IconButton onClick={() => setOpen(false)}>
                  <CloseIcon />
                </IconButton>
              </Hidden>
            </Box>
            <Box>
             <Formik
              initialValues={{
                invitation: '',
                tnc: true,
                acknowledge: true
              }}
              onSubmit={handleInvitation}
              validationSchema={Yup.object().shape({
                invitation: Yup.string().required("Message is required."),
                tnc: Yup.boolean().oneOf([true], 'This field must be checked').required("Must be checked."),
                acknowledge: Yup.boolean().oneOf([true], 'This field must be checked').required("Must be checked.")
              })}
             >
               {({
                 errors,
                 handleBlur,
                 handleChange,
                 handleSubmit,
                 touched,
                 values
               }) => (
                 <form onSubmit={handleSubmit} >
                    <Box mb={2} display="flex" alignItems="center">
                      <Typography sx={{fontSize: 14}}>To:</Typography>
                      <Typography 
                        sx={{
                          fontSize: 14, 
                          color: "gray",
                          letterSpacing:"1px",
                          pl: 1
                        }}
                      >
                       {profile.email}
                      </Typography>
                    </Box>
                    <TextField 
                      fullWidth
                      placeholder="Hello, Please give some information of your requirements..."
                      multiline
                      rows={6}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      name="invitation"
                      error={Boolean(touched.invitation && errors.invitation)}
                      helperText={touched.invitation && errors.invitation}
  
                    />
                    <Box
                      sx={{
                        alignItems: 'center',
                        display: 'flex',
                        ml: -1,
                        mt: 2
                      }}
                    >
                      <Checkbox
                        checked={values.tnc}
                        name="tnc"
                        onChange={handleChange}
                      />
                      <Typography
                        // color="primary"
                        variant="body2"
                      >
                        Yes I Agree, I've read all the
                        {' '}
                        <Link
                          color="primary"
                          component={RouterLink}
                          to="#"
                          underline="always"
                          variant="h6"
                        >
                          Terms and Conditions
                        </Link>
                      </Typography>
                    </Box>
                    {Boolean(touched.tnc && errors.tnc) && (
                      <FormHelperText error>
                        {errors.tnc}
                      </FormHelperText>
                    )}
                    <Box
                      sx={{
                        alignItems: 'center',
                        display: 'flex',
                        ml: -1,
                        // mt: 0.5
                      }}
                    >
                      <Checkbox
                        checked={values.acknowledge}
                        name="acknowledge"
                        onChange={handleChange}
                      />
                      <Typography
                        variant="body2"
                      >
                        Yes I Acknowledge to share company employees information, to the Doctor.
                      </Typography>
                    </Box>
                    {Boolean(touched.acknowledge && errors.acknowledge) && (
                      <FormHelperText error>
                        {errors.acknowledge}
                      </FormHelperText>
                    )}
                    <Box mt={7} display="flex" justifyContent="flex-end">
                      <Button
                        variant="contained"
                        type="submit"
                        sx={{
                          textTransform: "capitalize"
                        }}
                      >
                        Send Message
                      </Button>
                    </Box>
                 </form>
               )}
             </Formik>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Dialog>
  )
}

export default EmailComposer;