import {
  GET_STATS,
  GET_PEOPLE_ON_HIGH_RISK,
  GET_NOF_TEST_GRAPH,
  GET_LAST_TEN_TEST,
} from "../constants/dashboard";

const initialState = {
  allStats: null,
  allOnHighRisk: null,
  nofTestGraph: null,
  lastTenTest: null,
}

export default function dashboard(state = initialState, action){
  switch(action.type){

    case GET_STATS:
      return { ...state, allStats: action.data }

    case GET_PEOPLE_ON_HIGH_RISK:
      return { ...state, allOnHighRisk: action.data }

    case GET_NOF_TEST_GRAPH:
      return { ...state, nofTestGraph: action.data }

    case GET_LAST_TEN_TEST:
      return { ...state, lastTenTest: action.data }

    default: 
      return state;

  }
}