import {
  Card,
  CardContent,
  Grid,
  Typography
} from '@material-ui/core';

// Stylesheet
import classes from 'src/styles/dashboard/dashboard.module.scss';

const TotalTestDone = ({testCount, empCount, ...props}) => (
  <Card 
    sx={{ 
      height: '100%',
      boxShadow: '0px 2px 12px rgb(21 21 22 / 4%) !important',
      border: '1px solid #E6EAEE',
      borderRadius: "13px"
    }} 
    {...props}
  >
    <CardContent>
      <Grid container spacing={3} className={classes.ttd__container}>
        <Grid item>
          <Typography className={classes.ttd__title} gutterBottom variant="h6">
            Total Employee/Test Done
          </Typography>
          <Typography className={classes.ttd__stat} variant="h3">
            {empCount}/{testCount}
          </Typography>
        </Grid>
      </Grid>
    </CardContent>
  </Card>
);

export default TotalTestDone;
