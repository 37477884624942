import moment from 'moment';
import {
  Box,
  Button,
  Card,
  CardHeader,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Avatar,
  Typography,
  Hidden
} from '@material-ui/core';
import VisibilityIcon from '@material-ui/icons/Visibility';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import { useNavigate } from 'react-router-dom';
import getInitials from 'src/utils/getInitials';


const HighRiskPeople = ({people, ...props}) => {
  const navigate = useNavigate();
  return(
    <Card 
      sx={{
        boxShadow: '0px 2px 12px rgb(21 21 22 / 4%) !important',
        border: '1px solid #E6EAEE',
        borderRadius: "13px",
        height: "100%"
      }}
      {...props}
    >
      <CardHeader subtitle={`${people.length} in total`} title="On High Risk" />
      {
        people.length > 0
        ?
        <>
          <List
            sx={{
              maxHeight: "390px",
              overflow: "hidden",
              overflowY: "scroll",
              minHeight: "390px"
            }}
          >
            {people.map((emp, i) => (
              <ListItem
                key={emp.id}
              >
                <ListItemAvatar>
                  <Avatar
                    // src={emp.avatarUrl}
                    sx={{
                      mr: 2,
                      background: '#2D809F',
                      fontSize: 16
                    }}
                  >
                    {getInitials(emp.patient)}
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={emp.patient}
                  secondary={`Updated ${moment(emp.created_on).fromNow()}`}
                  primaryTypographyProps={{
                    style: {
                      textTransform: "capitalize"
                    }
                  }}
                />
                <IconButton 
                  edge="end" 
                  size="small"
                  onClick={() => navigate(`/app/employee-info/${emp.emp_code}/${emp.profile_id}`)}
                >
                  <VisibilityIcon />
                </IconButton>
              </ListItem>
            ))}
          </List>
          <Divider />
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              p: 1
            }}
          >
            <Button
              color="primary"
              endIcon={<ArrowRightIcon />}
              size="small"
              variant="text"
              onClick={() => navigate(`/app/employee/${'people-on-alert'}`)}
            >
              View all
            </Button>
          </Box>
        </>
        :
        <Box
          sx={{
            display:"flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            maxHeight: "390px",
            minHeight: "390px"
          }}
        > 
          <img src="/static/images/no_data.svg" alt="no_data_available" width="100px" />
          <Typography
            sx={{
              fontSize: 16,
              paddingTop: 3,
              color: "gray",
              fontWeight: 500, 
              letterSpacing: "1.5px"
            }}
          >
            <Hidden lgDown>
              No Employees <br /> at High Risk
            </Hidden>
            <Hidden lgUp>
              No Employees at High Risk
            </Hidden>
          </Typography>
        </Box>
      }
      
    </Card>
  );
}

export default HighRiskPeople;