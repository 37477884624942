
/** ================================================
 * 
 * No Need Of this component 
 * Because we changes this into tabular view 
 * We keet it because if we need this in future so 
 * we can use na :)
 *  
================================================ */

import { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Box, Container, Typography, TextField } from '@material-ui/core';

import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import LocalizationProvider from '@material-ui/lab/LocalizationProvider';
import DateRangePicker from '@material-ui/lab/DateRangePicker';
import moment from 'moment';
import { useParams, useNavigate } from 'react-router-dom';

// #React-redux
import { connect } from 'react-redux';
import { report } from 'src/redux/actions';

// Vitals View
import BloodPressureView from 'src/components/reports/BPView';
import TempratureView from 'src/components/reports/TemperatureView';
import WeightView from 'src/components/reports/WeightView';
import PulseView from 'src/components/reports/PulseView';
import GlucoseView from 'src/components/reports/GlucoseView';
import SPO2View from 'src/components/reports/SPO2View';
import EmployeeDetailsView from 'src/components/reports/detailsCard';

// Stylesheet
import classes from 'src/styles/reports/reports.module.scss';

/**================================================ */

/**
 * Parameters -> Unit
 * Blood Pressure -> mmHg (millimeters of mercury)
 * Temprature -> Celsius
 * Weight -> Kilogram
 * Pulse -> bpm (beats per minute)
 * Glucose -> mg/dL (milligrams per decilitre)
 * SpO2 -> % (Percentage)
 */

/**================================================ */

const PatientReport = ({ 
  getEmployee, 
  employeeProfile,
  fetchVitalsReports,
  bpReadings,
  bpGraph,
  tempReadings,
  tempGraph,
  glucoseReadings,
  glucoseGraph,
  pulseReadings,
  pulseGraph,
  weightReadings,
  weightGraph,
  spo2Readings,
  spo2Graph,
}) => {
  const [dateRange, setDateRange] = useState([
    moment().subtract(30, 'days'),
    new Date()
  ]);
  const { slug, id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    getEmployee(slug);
  }, [slug]);

  // Fetch Vitals with date-range
  useEffect(() => {
    const startDate = moment(dateRange[0]).format("DD-MM-YY");
    const endDate = moment(dateRange[1]).format("DD-MM-YY")
    console.log("Hello World",startDate, endDate, dateRange[1])
    
    let formDate = moment().subtract(30, "days").format("DD-MM-YY")
    let query = `from_date=${startDate}&to_date=${endDate}&profile_id=${id}`;
    fetchVitalsReports(query);
  },[dateRange])

  // Handling Date Change
  const handleDateChange = (dateRange) => {
    setDateRange(dateRange);
  };

  return (
    <>
      <Helmet>
        <title>Report | TraceLyfe</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.secondary',
          minHeight: '100%',
          py: 3
        }}
      >
        <Container maxWidth={false} className={classes.rt__root}>
          <Box className={classes.rt__header}>
            <Box>
              <Typography className={classes.rt__breadcrumbs}>
                  <span style={{cursor: "pointer"}} onClick={() => navigate("/app/employee")}>Employee</span>
                  <span style={{padding: "0px 5px"}}>|</span> 
                  <span style={{color: "#0d809f"}}>Employee Report</span>
              </Typography>
              <Typography className={classes.rt__title}>
                Employee Report
              </Typography>
            </Box>
            <Box className={classes.rt__inner__box}>
              {
                employeeProfile && 
                <EmployeeDetailsView profile={employeeProfile} />
              }
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DateRangePicker
                  value={dateRange}
                  inputFormat="dd/MM/yyyy"
                  onChange={(newRange) => handleDateChange(newRange)}
                  disableFuture
                  // disablePast
                  // minDate={moment("2021-09-20")}
                  renderInput={(startProps, endProps) => (
                    <>
                      <TextField variant="standard" {...startProps} />
                      <Box sx={{ mx: 2 }}>to</Box>
                      <TextField variant="standard" {...endProps} />
                    </>
                  )}
                />
              </LocalizationProvider>
            </Box>
          </Box>

          {/* Blood Pressure */}

          <Box>
            {
              bpReadings && bpGraph &&
              <BloodPressureView tableData={bpReadings} graphData={bpGraph} />
            }
          </Box>

          {/* Temprature */}

          <Box py={(2.5)}>
            {
              tempReadings && tempGraph && 
              <TempratureView tableData={tempReadings} graphData={tempGraph}  />
            }
          </Box>

          {/* Weight */}

          <Box py={2.5}>
            {
              weightReadings && weightGraph &&
              <WeightView tableData={weightReadings} graphData={weightGraph} />
            }
          </Box>

          {/* Pulse */}

          <Box py={2.5}>
            {
              pulseReadings && pulseGraph &&
              <PulseView tableData={pulseReadings} graphData={pulseGraph} />
            }
          </Box>

          {/* Glucose */}

          <Box py={2.5}>
            {
              glucoseReadings && glucoseGraph &&
              <GlucoseView tableData={glucoseReadings} graphData={glucoseGraph} />
            }
          </Box>

          {/* SPO2 */}

          <Box py={2.5}>
            {
              spo2Readings && spo2Graph &&
              <SPO2View tableData={spo2Readings} graphData={spo2Graph} />
            }
          </Box>
        </Container>
      </Box>
    </>
  );
};

const mapStateToProps = (state) => ({
  employeeProfile: state.report.employeeProfile,
  bpReadings: state.report.bpReadings,
  bpGraph: state.report.bpGraph,
  tempReadings: state.report.tempReadings,
  tempGraph: state.report.tempGraph,
  glucoseReadings: state.report.glucoseReadings,
  glucoseGraph: state.report.glucoseGraph,
  pulseReadings: state.report.pulseReadings,
  pulseGraph: state.report.pulseGraph,
  weightReadings: state.report.weightReadings,
  weightGraph: state.report.weightGraph,
  spo2Readings: state.report.spo2Readings,
  spo2Graph: state.report.spo2Graph,
});

const mapDispatchToProps = (dispatch) => ({
  getEmployee: (emp_id) => dispatch(report.getEmployee(emp_id)),
  fetchVitalsReports: (queryParams) => dispatch(report.fetchVitalsReports(queryParams)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PatientReport);
