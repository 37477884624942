import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  Avatar,
  Box,
  Card,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  Button
} from '@material-ui/core';
import { useNavigate, useParams } from 'react-router-dom';

import getInitials from '../../utils/getInitials';

const EmployeeListResults = ({
  employee,
  fetchEmployee,
  loading,
  setLoading,
  ...rest
}) => {
  const [limit, setLimit] = useState(12);
  const [page, setPage] = useState(1);
  const navigate = useNavigate();
  const { slug } = useParams();

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage + 1);
    if (slug === 'people-on-alert') {
      fetchEmployee(`page=${newPage + 1}&status=alert`);
    } else if (slug === 'people-on-moderate') {
      fetchEmployee(`page=${newPage + 1}&status=moderate`);
    } else if (slug === 'people-on-low') {
      fetchEmployee(`page=${newPage + 1}&status=good`);
    } else {
      fetchEmployee(`page=${newPage + 1}`);
    }
  };

  return (
    <Card {...rest}>
      <Box>
        <Table className="table__container__emp">
          <TableHead sx={{ background: '#0D809F' }}>
            <TableRow>
              <TableCell sx={{color: "#fff"}}>Employee ID</TableCell>
              <TableCell sx={{color: "#fff"}}>Name</TableCell>
              <TableCell sx={{color: "#fff"}}>Email</TableCell>
              <TableCell sx={{color: "#fff"}}>Location</TableCell>
              <TableCell sx={{color: "#fff"}}>Phone</TableCell>
              <TableCell sx={{color: "#fff"}}>Gender</TableCell>
              <TableCell sx={{color: "#fff"}}>Registration date</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {employee.results.slice(0, limit).map((emp, index) => (
              <TableRow
                hover
                key={emp.index}
                sx={{
                  cursor: 'pointer'
                }}
                onClick={() =>
                  navigate(`/app/employee-info/${emp.employee_code}/${emp.id}`)
                }
              >
                <TableCell>{emp.employee_code}</TableCell>
                <TableCell>
                  <Box
                    sx={{
                      alignItems: 'center',
                      display: 'flex'
                    }}
                  >
                    <Avatar
                      // src={emp.avatarUrl}
                      sx={{
                        mr: 2,
                        background: '#2D809F',
                        fontSize: 16
                      }}
                    >
                      {getInitials(emp.name)}
                    </Avatar>
                    <Typography
                      color="textPrimary"
                      variant="body1"
                      sx={{
                        textTransform: 'capitalize'
                      }}
                    >
                      {emp.name}
                    </Typography>
                  </Box>
                </TableCell>
                <TableCell>{emp.email}</TableCell>
                <TableCell>{`${emp.address}`}</TableCell>
                <TableCell>{emp.phone_number}</TableCell>
                <TableCell
                  sx={{
                    textTransform: 'capitalize'
                  }}
                >
                  {emp.gender}
                </TableCell>
                <TableCell>{moment(emp.created_on).format('ll')}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Box>
      <TablePagination
        component="div"
        count={employee.count}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleLimitChange}
        page={page - 1}
        rowsPerPage={limit}
        rowsPerPageOptions={[12]}
      />
    </Card>
  );
};

EmployeeListResults.propTypes = {
  employee: PropTypes.array.isRequired,
  fetchEmployee: PropTypes.any.isRequired
};

export default EmployeeListResults;
