import axios from "axios";
import {
  GET_EMPLOYEE_DETAIL,
  LAST_RECENT_TESTS,
  FETCH_VITALS_TABLE_DATA,
  FETCH_VITALS_GRAPH_DATA,
  FETCH_EMPLOYEE_REPORT,
  LOCATION_CHANGE
} from "../constants/report";

const REPORT_BASE_URL = `${process.env.REACT_APP_API_URL}/employee/api`;
let headers = { 'Content-Type': 'application/json' };

export const fetchVitalsReports = (queryParams) => async (dispatch, getState) => {
  try {
    const token = getState().auth.access_token;
    if (token) {
      headers['Authorization'] = `Bearer ${token}`;
      const response = await axios.get(`${REPORT_BASE_URL}/vital-graph-data/?${queryParams}`, {
        headers
      });
      if (response.status === 200 || response.status === 201) {
        dispatch({ type: FETCH_VITALS_TABLE_DATA, data: response.data.data })
        dispatch({ type: FETCH_VITALS_GRAPH_DATA, data: response.data.graph_data })
      }
    }
  } catch (error) {
    throw error;
  }
};

export const getEmployee = (id) => async (dispatch, getState) => {
  try {
    const token = getState().auth.access_token;
    if (token) {
      headers['Authorization'] = `Bearer ${token}`;
      const response = await axios.get(`${REPORT_BASE_URL}/employee_details/?employee_code=${id}`, {
        headers
      });
      if (response.status === 200) {
        dispatch({ type: GET_EMPLOYEE_DETAIL, data: response.data.data });
      }
    }
  } catch (error) {
    throw error;
  }
};

export const lastRecentTests = (data) => async (dispatch, getState) => {
  try {
    const token = getState().auth.access_token;
    if (token) {
      headers['Authorization'] = `Bearer ${token}`;
      const response = await axios.get(`${REPORT_BASE_URL}/last_tested_employee_data/?empID=${data}`, {
        headers
      });
      if (response.status === 200) {
        dispatch({ type: LAST_RECENT_TESTS, data: response.data.data });
      }
    }
  } catch (error) {
    throw error;
  }
};

export const fetchEmployeeReports = (query) => async (dispatch, getState) => {
  try {
    const token = getState().auth.access_token;
    if (token) {
      headers['Authorization'] = `Bearer ${token}`;
      const response = await axios.get(`${REPORT_BASE_URL}/get-employee-reports/?${query}`, {
        headers
      });
      if (response.status === 200) {
        dispatch({ type: FETCH_EMPLOYEE_REPORT, data: response.data.data });
      }
    }
  } catch (error) {
    throw error;
  }
};

export const viewDownloadReport = (data) => async (dispatch, getState) => {
  try {
    const token = getState().auth.access_token;
    if (token) {
      headers['Authorization'] = `Bearer ${token}`;
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/doctor/api/get-employee-report/?${data}`, {
        headers
      });
      if (response.status === 200) {
        return response
      }
    }
  } catch (error) {
    throw error;
  }
};

export const locationChange = () =>  (dispatch, getState) => {
  dispatch({type: LOCATION_CHANGE})
}