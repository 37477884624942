
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer
} from 'recharts';


export default function LineChartView({ graphData }) {


  /**
   * 
   * Object key changed based on requirement 
   * reading1 -> Systolic
   * reading2 -> Diastolic
   * 
   */
  const changedKey = graphData.map(({
    reading1: Systolic,
    reading2: Diastolic,
    ...rest
  }) => ({
    Systolic,
    Diastolic,
    ...rest
  }));

  return (
    <ResponsiveContainer className="responsive__chart">
      <LineChart
        data={changedKey}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Line
          type="monotone"
          dataKey="Systolic"
          stroke="#0D809F"
          activeDot={{ r: 8 }}
        />
        <Line type="monotone" dataKey="Diastolic" stroke="#F47920" />
      </LineChart>
    </ResponsiveContainer>
  );
}
